import * as posTillEndpoints from "../../lib/api/till";
import CashPullIcon from "../../images/cash-pull-icon.svg";
import CurrencyInput from "../CurrencyInput";
import Drawer from "../Drawer";
import ManagerPermissionForm from "../ManagerPermissionForm";
import OutlineButton from "../OutlineButton";
import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./CashPullDrawer.module.css";
import TillIcon from "../../images/till.svg";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {CashPullContext} from "../CashPullContext";
import {TillAssignmentContext} from "../TillAssignmentContext";
import describeNetworkError from "../../lib/describeNetworkError";
import {openCashDrawer} from "../../lib/CashDrawer/CashDrawerPlugin";

const strings = {
    cashPull: "Cash Pull",
    suggestedPullAmount: "Suggested Pull Amount: ",
    submit: "Submit",
    inputPlaceholder: "Enter Amount",
    openTill: "Open Till",
    maxExceededErrorMessage:
        "The amount entered exceeds the max amount that can be pulled from this till.",
    unexpectedTillOpenError:
        "An unexpected error occurred while attempting to open the cash drawer. Please try again.",
    retryTillOpenPrompt:
        "The till could not be opened to perform the cash pull. Please Try Again.",
};

type Props = {
    onClose: () => void;
};

export function CashPullDrawer(props: Props) {
    const {assignedTill} = useContext(TillAssignmentContext);
    const {
        setSkipCurrentAmountCheck,
        suggestedPullAmountCents,
        maxPullAmountCents,
        setCurrentAmountCents,
        setSuggestedPullAmountCents,
    } = useContext(CashPullContext);
    const [pullAmountCents, setPullAmountCents] =
        useState<API.models.USDCents | null>(null);
    const [displayedPullAmount, setDisplayedPullAmount] = useState("");
    const onChangePullAmount = useOnChangeCashAmount(
        setDisplayedPullAmount,
        setPullAmountCents,
    );
    useEffect(() => {
        const floatVal = parseFloat(displayedPullAmount);
        if (
            isNaN(floatVal) ||
            pullAmountCents !== Lib.currency.dollarsFloatToCents(floatVal)
        ) {
            setDisplayedPullAmount(
                pullAmountCents !== null
                    ? Lib.currency.centsToDollarString(pullAmountCents, true)
                    : "",
            );
        }

        if (errorMessage) {
            setErrorMessage("");
        }
    }, [pullAmountCents]);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [tillOpenErrorMessage, setTillOpenErrorMessage] = useState("");
    const [showPullContent, setShowPullContent] = useState(false);
    const [showTillOpenContent, setShowTillOpenContent] = useState(false);
    const [returnedManagerId, setReturnedManagerId] = useState("");
    const cashPullAmountInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (showPullContent) {
            cashPullAmountInputRef.current?.focus();
        }
    }, [showPullContent]);

    const onDismissDrawer = () => {
        setSkipCurrentAmountCheck(true);
        props.onClose();
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!pullAmountCents) {
            return;
        }
        setLoading(true);
        if (errorMessage) {
            setErrorMessage("");
        }
        posTillEndpoints
            .logCashPull(assignedTill, pullAmountCents, returnedManagerId)
            .then(() => {
                setCurrentAmountCents(0); // we could technically track the cash in the system TODO
                setSuggestedPullAmountCents(0);
                setSkipCurrentAmountCheck(false);
                props.onClose();
            })
            .catch((err) => {
                setErrorMessage(describeNetworkError(err).join("\n"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getRetryTillOpenContent = () => {
        return (
            <div className={styles.tillOpenContentContainer}>
                <img src={TillIcon} alt="" />
                <div className={styles.tillOpenPrompt}>
                    {strings.retryTillOpenPrompt}
                </div>
                <OutlineButton
                    className={styles.tillOpenButton}
                    label={strings.openTill}
                    onClick={() => {
                        setLoading(true);
                        setTillOpenErrorMessage("");
                        openCashDrawer()
                            .then(() => {
                                setShowPullContent(true);
                                setShowTillOpenContent(false);
                            })
                            .catch((err) => {
                                if (err.message) {
                                    setTillOpenErrorMessage(err.message);
                                } else {
                                    setTillOpenErrorMessage(
                                        strings.unexpectedTillOpenError,
                                    );
                                }
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                    loading={loading}
                />
                {tillOpenErrorMessage ? (
                    <div className={styles.errorMessage}>
                        {tillOpenErrorMessage}
                    </div>
                ) : null}
            </div>
        );
    };

    const getPullAmountExceedsMax = () => {
        return pullAmountCents !== null && pullAmountCents > maxPullAmountCents;
    };

    const getErrorContent = () => {
        const pullAmountExceedsMax = getPullAmountExceedsMax();
        let errorStr = errorMessage;
        if (pullAmountExceedsMax) {
            errorStr = strings.maxExceededErrorMessage;
        }

        return errorStr ? (
            <div className={styles.errorMessage}>{errorStr}</div>
        ) : null;
    };

    const getManagerVerifiedContent = () => {
        return (
            <>
                <form className={styles.cashPullForm} onSubmit={onSubmit}>
                    <img
                        className={styles.cashPullIcon}
                        src={CashPullIcon}
                        alt=""
                    />
                    <div className={styles.suggestedPullAmountContainer}>
                        <span className={styles.suggestedPullAmountLabel}>
                            {strings.suggestedPullAmount}
                        </span>
                        <span className={styles.suggestedPullAmountValue}>
                            {Lib.currency.centsToDollarString(
                                suggestedPullAmountCents,
                            )}
                        </span>
                    </div>
                    <CurrencyInput
                        className={styles.cashPullInput}
                        placeholder={strings.inputPlaceholder}
                        value={displayedPullAmount}
                        onChange={(num) => setPullAmountCents(num)}
                        ref={cashPullAmountInputRef}
                    />
                    <OutlineButton
                        className={styles.pullAmountSubmitButton}
                        label={strings.submit}
                        type="submit"
                        loading={loading}
                        disabled={!pullAmountCents}
                    />
                    {getErrorContent()}
                </form>
                <div className={styles.keyboardContainer}>
                    <StyledKeyboard
                        currentInput={displayedPullAmount}
                        alwaysVisible
                        onChange={onChangePullAmount}
                        inputRefs={[cashPullAmountInputRef]}
                        layout={"numeric"}
                        disableCaretPositioning
                    />
                </div>
            </>
        );
    };

    const getContent = () => {
        if (showPullContent) {
            return getManagerVerifiedContent();
        } else if (showTillOpenContent) {
            return getRetryTillOpenContent();
        } else {
            return (
                <ManagerPermissionForm
                    onSuccess={(resp) => {
                        setReturnedManagerId(resp.id);
                        openCashDrawer()
                            .then(() => {
                                setShowPullContent(true);
                            })
                            .catch(() => {
                                setShowTillOpenContent(true);
                            });
                    }}
                />
            );
        }
    };

    return (
        <Drawer isSmall={true} onClose={onDismissDrawer}>
            <div className={styles.keyboardAndFormContainer}>
                <div className={styles.header}>
                    <div className={styles.headerText}>{strings.cashPull}</div>
                </div>
                {getContent()}
            </div>
        </Drawer>
    );
}
