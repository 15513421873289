import React, {createContext, useState} from "react";
import {API} from "habit-core";

type CashPullContextType = {
    currentAmountCents: API.models.USDCents;
    setCurrentAmountCents: (amount: API.models.USDCents) => void;
    suggestedPullAmountCents: API.models.USDCents;
    setSuggestedPullAmountCents: (amount: API.models.USDCents) => void;
    maxPullAmountCents: API.models.USDCents;
    setMaxPullAmountCents: (amount: API.models.USDCents) => void;
    skipCurrentAmountCheck: boolean;
    setSkipCurrentAmountCheck: (skipCurrentAmountCheck: boolean) => void;
    cashPullAlertVisible: boolean;
    setCashPullAlertVisible: (visible: boolean) => void;
};

export const CashPullContext = createContext<CashPullContextType>({
    currentAmountCents: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCurrentAmountCents: () => {},
    suggestedPullAmountCents: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSuggestedPullAmountCents: () => {},
    maxPullAmountCents: 0,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMaxPullAmountCents: () => {},
    skipCurrentAmountCheck: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSkipCurrentAmountCheck: () => {},
    cashPullAlertVisible: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCashPullAlertVisible: () => {},
});

export function CashPullContextProvider(props: {children: React.ReactNode}) {
    const [currentAmountCents, setCurrentAmountCents] =
        useState<API.models.USDCents>(0);
    const [suggestedPullAmountCents, setSuggestedPullAmountCents] = useState(0);
    const [maxPullAmountCents, setMaxPullAmountCents] = useState(0);
    const [skipCurrentAmountCheck, setSkipCurrentAmountCheck] = useState(false);
    const [cashPullAlertVisible, setCashPullAlertVisible] = useState(false);

    return (
        <CashPullContext.Provider
            value={{
                currentAmountCents,
                setCurrentAmountCents,
                suggestedPullAmountCents,
                setSuggestedPullAmountCents,
                maxPullAmountCents,
                setMaxPullAmountCents,
                skipCurrentAmountCheck,
                setSkipCurrentAmountCheck,
                cashPullAlertVisible,
                setCashPullAlertVisible,
            }}>
            {props.children}
        </CashPullContext.Provider>
    );
}
