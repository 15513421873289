import Modal from "../Modal";
import OutlineButton from "../OutlineButton";
import React, {useContext} from "react";
import styles from "./CashPullModal.module.css";
import {CashPullContext} from "../CashPullContext";

const strings = {
    title: "Cash Pull Required",
    message:
        "The amount of cash in the till exceeds the suggested amount. Please ask a manager to complete a cash pull.",
    proceedLabel: "Proceed With Cash Pull",
    snooze: "Snooze",
};

type Props = {
    onClickProceed: () => void;
};

export function CashPullModal(props: Props) {
    const {setSkipCurrentAmountCheck, setCashPullAlertVisible} =
        useContext(CashPullContext);

    const onProceed = () => {
        setCashPullAlertVisible(false);
        props.onClickProceed();
    };

    const onSnooze = () => {
        setSkipCurrentAmountCheck(true);
        setCashPullAlertVisible(false);
    };

    return (
        <Modal className={styles.modal}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>{strings.title}</div>
                <div className={styles.message}>{strings.message}</div>
                <OutlineButton
                    className={styles.button}
                    label={strings.proceedLabel}
                    onClick={onProceed}
                />
                <OutlineButton
                    className={styles.button}
                    label={strings.snooze}
                    onClick={onSnooze}
                />
            </div>
        </Modal>
    );
}
