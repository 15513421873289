import * as posModels from "./posModels";
import instance from "./instance";
import {API} from "habit-core";
import {tills as urls} from "./urls";

export function logTillAssignment(
    deviceId: string,
    tillNumber: number,
): Promise<void> {
    return instance
        .request({
            url: urls.logAssignment,
            method: "POST",
            data: {
                deviceId,
                tillNumber,
            },
        })
        .then((response) => response.data);
}

export function getCurrentAssignments(): Promise<posModels.TillAssignments> {
    return instance
        .request({
            url: urls.getAssignments,
            method: "GET",
        })
        .then((response) => response.data);
}

export function getCurrentAmount(
    tillNumber: number,
): Promise<posModels.TillCurrentAmountResponse> {
    return instance
        .request({
            url: urls.getCurrentAmount,
            params: {
                tillNumber,
            },
            method: "GET",
        })
        .then((response) => response.data);
}

export function logCashPull(
    tillNumber: number,
    pulledAmountCents: API.models.USDCents,
    managerId: string,
): Promise<void> {
    return instance
        .request({
            url: urls.logCashPull,
            method: "POST",
            data: {
                tillNumber,
                pulledAmountCents,
                managerId,
            },
        })
        .then((response) => response.data);
}
