import * as posActions from "../../lib/posActions";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import OutlineButton from "../OutlineButton";
import React, {useEffect, useRef, useState} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./CheckoutStep1.module.css";
import TextButton from "../TextButton";
import TextInput from "../TextInput";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {useNavigate, useLocation} from "react-router-dom";

const strings = {
    toGo: "To-Go",
    dineIn: "Dine-In",
    submit: "Submit",
    skip: "Skip",
    orderType: "Select Packaging",
    name: "Pager Number or Guest Name",
    namePlaceholder: "Enter Here",
    yes: "Yes",
    no: "No",
    roundUpMinimalPrompt: "Round-Up To The Nearest Dollar ",
    roundUpPromptStub1: "Would You Like To Round Up Your Total To ",
    roundUpPromptStub2: " And Donate To ",
    askCustomer: "Ask Guest:",
    alphanumericKeyboard: "ABC Keyboard",
    numericKeyboard: "Numeric Keyboard",
};

const MAX_LENGTH_PAGER_NUMBER = 4;
const MAX_LENGTH_CUSTUMER_NAME = 20;
function getMaxLength(input: string) {
    const numOnly = new RegExp(/^[0-9]*$/);
    return numOnly.test(input)
        ? MAX_LENGTH_PAGER_NUMBER
        : MAX_LENGTH_CUSTUMER_NAME;
}

type CheckoutStep1LocationState = {
    name?: string;
    removeRefNumDisabled?: boolean;
};

export default function CheckoutStep1() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const locationState = location.state as CheckoutStep1LocationState;
    const initialName = useAppSelector((state) => {
        if (state.pos.currentOrder.guestName) {
            return state.pos.currentOrder.guestName;
        } else if (state.pos.currentOrder.pagerNumber) {
            return state.pos.currentOrder.pagerNumber.toString();
        } else {
            return locationState?.name ?? "";
        }
    });

    const [name, setName] = useState(initialName);
    const onChangeName = (input: string) => {
        setName(input);
    };

    const stationMode = useAppSelector((state) => state.pos.station.mode);

    const onSubmit = () => {
        const onlyDigitsRegex = /^[0-9]+$/g;
        const hasOnlyDigits = onlyDigitsRegex.test(name);
        dispatch(
            posActions.setCurrentOrderCustomerInfo(
                hasOnlyDigits ? null : name,
                hasOnlyDigits ? parseInt(name) : null,
            ),
        );

        navigate(routeHelpers.payment(), {
            state: {
                name: name,
            },
        });
    };

    const onSkip = () => {
        navigate(routeHelpers.payment());
    };

    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);

    /* keyboard state */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const [keyboardLayout, setKeyboardLayout] = useState<"numeric" | "default">(
        "numeric",
    );
    const pagerNumberInputRef = useRef<HTMLInputElement>(null);
    const switchLayoutRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            !locationState?.removeRefNumDisabled &&
            posCurrentOrder.referenceNumber !== null
        ) {
            dispatch(posActions.removeCurrentOrderRefNum());
        }

        pagerNumberInputRef.current?.focus(); // trigger keyboard when arriving to this step.
    }, []);

    useEffect(() => {
        if (pagerNumberInputRef.current && !keyboardCollapsed) {
            if (document.activeElement === pagerNumberInputRef.current) {
                pagerNumberInputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    if (stationMode === "drive_thru_order_fulfillment") {
        return null;
    }

    return (
        <div className={styles.keyboardAndFormContainer}>
            <form className={styles.container} onSubmit={onSubmit}>
                <label className={styles.label}>
                    {strings.name}

                    <TextInput
                        className={styles.nameInput}
                        value={name}
                        onChange={(e) => onChangeName(e.target.value)}
                        placeholder={strings.namePlaceholder}
                        onFocus={() => setKeyboardCollapsed(false)}
                        ref={pagerNumberInputRef}
                        inputMode="none"
                        maxLength={getMaxLength(name)}
                    />
                </label>

                <div className={styles.buttonRow}>
                    <OutlineButton
                        className={styles.button}
                        label={strings.submit}
                        onClick={onSubmit}
                        type="submit"
                    />
                    <TextButton label={strings.skip} onClick={onSkip} />
                </div>
            </form>
            {!keyboardCollapsed ? (
                <div
                    ref={switchLayoutRef}
                    className={styles.switchLayoutButtonContainer}>
                    <TextButton
                        label={
                            keyboardLayout === "numeric"
                                ? strings.alphanumericKeyboard
                                : strings.numericKeyboard
                        }
                        onClick={() => {
                            if (keyboardLayout === "default") {
                                setKeyboardLayout("numeric");
                            } else {
                                setKeyboardLayout("default");
                            }
                        }}
                    />
                </div>
            ) : null}
            <div
                className={cn(
                    styles.keyboardContainer,
                    keyboardCollapsed && styles.keyboardContainerCollapsed,
                )}>
                <StyledKeyboard
                    currentInput={name}
                    visible={!keyboardCollapsed}
                    setVisible={(val: boolean) => setKeyboardCollapsed(!val)}
                    onChange={onChangeName}
                    onPressEnter={onSubmit}
                    inputRefs={[pagerNumberInputRef]}
                    ignoreKeyboardDismissRefs={[switchLayoutRef]}
                    maxLength={getMaxLength(name)}
                    layout={keyboardLayout}
                />
            </div>
        </div>
    );
}
