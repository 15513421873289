import arrowIcon from "../../images/arrow-in-circle.svg";
import PaymentHeader from "../PaymentHeader";
import React from "react";
import styles from "./PaymentMethodsHeader.module.css";
import {useLocation, useNavigate} from "react-router-dom";

type Props = {
    backPromptLabel: string;
    to: string;
};

type NameState = {
    name?: string;
};

export function PaymentMethodsHeader(props: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;

    return (
        <div className={styles.headerContainer}>
            <PaymentHeader
                showCheckoutReturnPrompt={false}
                customerName={customerName}
            />
            <div className={styles.backPrompt}>
                <button
                    className={styles.backPromptButton}
                    onClick={() => {
                        navigate(props.to, {
                            state: {
                                name: customerName,
                            },
                        });
                    }}>
                    <img src={arrowIcon} alt="" />
                    <div className={styles.backPromptText}>
                        {props.backPromptLabel}
                    </div>
                </button>
            </div>
        </div>
    );
}
