import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import flatten from "lodash.flatten";
import OutlineButton from "../OutlineButton";
import QuantityControl from "../QuantityControl";
import React, {useMemo, useState} from "react";
import styles from "./PreCheckout.module.css";
import TextButton from "../TextButton";
import ToggleButton from "../ToggleButton";
import {Actions, API, Constants, Lib} from "habit-core";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useNavigate} from "react-router-dom";

const strings = {
    yes: "Yes",
    no: "No",
    charityPrompt: (charityName: string) =>
        `"Would You Like To Donate To ${charityName}?"`,
    condimentPrompt:
        "Would you like to purchase any additional signature sauces with your meal today?",
    addToOrder: "Add To Order",
    continueToCheckout: "Continue To Checkout",
    removeDonation: "Remove Donation",
    roundUp: "Round Up",
};

const orderTypeStrings = {
    [Constants.orderType.DINE_IN]: "Dine-In",
    [Constants.orderType.TO_GO]: "To-Go",
    [Constants.orderType.DRIVE_THRU]: "Drive-Thru",
};

type QsrQuestion = {
    value: posModels.QsrSelection;
    question: string;
};

const ketchupQuestion: QsrQuestion = {
    value: "ketchup",
    question: '"Would you like any ketchup packets with your order today?"',
};

const utensilsQuestion: QsrQuestion = {
    value: "utensils",
    question: '"Would you like any Utensils with your order today?"',
};

const napkinsQuestion: QsrQuestion = {
    value: "napkins",
    question: '"Would you like any Napkins with your order today?"',
};

const HERE_QUESTIONS: QsrQuestion[] = [utensilsQuestion];

const DEFAULT_QUESTIONS: QsrQuestion[] = [
    utensilsQuestion,
    napkinsQuestion,
    ketchupQuestion,
];

const CHARITY_DOLLAR_OPTIONS = [500, 1000, 1500, 2000];

export function PreCheckout() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // TODO: figure out if we want to add the ability to edit order type outside of in CashierCheckoutItems
    const orderType = useAppSelector((state) => state.currentOrder.orderType);

    const charitySettings = useAppSelector(
        (state) => state.pos.charitySettings,
    );
    const shouldRoundUp = useAppSelector(
        (state) => state.currentOrder.shouldRoundUp,
    );
    const charityAmountCents = useAppSelector(
        (state) => state.pos.currentOrder.charityAmountCents,
    );

    const menuId = useAppSelector((state) => state.currentOrder.menuId);
    const getSections = useMemo(
        () => Lib.selectors.createGetMenuCategorySections(false, true),
        [],
    );
    const condimentCategorysections = useAppSelector((state) =>
        getSections(
            state,
            "9ef0a939-8b1a-540f-c1ef-544b9d575a2a",
            menuId ?? "",
        ),
    );
    const condimentProducts = flatten(
        condimentCategorysections.map((x) => x.data),
    );
    const itemsById = useAppSelector((state) => state.items.byId);
    const [selectedItemId, setSelectedItemId] = useState("");
    const [condimentQuantity, setCondimentQuantity] = useState(1);

    const setShouldRoundUp = (newVal: boolean) => {
        if (charityAmountCents) {
            dispatch(posActions.currentOrderSetCharityAmountCents(null));
        }
        dispatch(Actions.currentOrderActions.setShouldRoundUp(newVal));
    };

    const setCharityAmount = (amountCents: API.models.USDCents | null) => {
        if (shouldRoundUp) {
            dispatch(Actions.currentOrderActions.setShouldRoundUp(false));
        }
        dispatch(posActions.currentOrderSetCharityAmountCents(amountCents));
    };

    const qsrSelections = useAppSelector(
        (state) => state.pos.currentOrder.qsrSelections,
    );
    const [utensilsChecked, setUtensilsChceked] = useState(
        qsrSelections?.includes("utensils") ?? false,
    );
    const [napkinsChecked, setNapkinsChecked] = useState(
        qsrSelections?.includes("napkins") ?? false,
    );
    const [ketchupChecked, setKetchupChceked] = useState(
        qsrSelections?.includes("ketchup") ?? false,
    );

    const renderCharitySection = () => {
        if (!charitySettings.isEnabled || charitySettings.mode === null) {
            return null;
        }

        if (charitySettings.mode === "round_up_only") {
            return (
                <div className={styles.questionContainer}>
                    <div className={styles.question}>
                        {charitySettings.prompt ??
                            strings.charityPrompt(
                                charitySettings.charityName ?? "",
                            )}
                    </div>
                    <div className={styles.optionsContainer}>
                        <ToggleButton
                            className={styles.toggleButton}
                            checkedClassName={styles.toggleButtonChecked}
                            label={strings.yes}
                            checked={shouldRoundUp}
                            onChange={() => setShouldRoundUp(true)}
                        />
                        <ToggleButton
                            className={styles.toggleButton}
                            checkedClassName={styles.toggleButtonChecked}
                            label={strings.no}
                            checked={!shouldRoundUp}
                            onChange={() => setShouldRoundUp(false)}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.questionContainer}>
                <div className={styles.charityPromptAndRemoveContainer}>
                    <div className={styles.question}>
                        {charitySettings.prompt ??
                            strings.charityPrompt(
                                charitySettings.charityName ?? "",
                            )}
                    </div>
                    <TextButton
                        className={styles.removeDonationButton}
                        label={strings.removeDonation}
                        onClick={() => {
                            setCharityAmount(null);
                            setShouldRoundUp(false);
                        }}
                    />
                </div>

                <div className={styles.optionsContainer}>
                    {CHARITY_DOLLAR_OPTIONS.map((charityDollarOption, i) => {
                        return (
                            <ToggleButton
                                key={i}
                                className={styles.toggleButtonSmall}
                                checkedClassName={
                                    styles.toggleButtonSmallChecked
                                }
                                label={Lib.currency.centsToDollarString(
                                    charityDollarOption,
                                )}
                                checked={
                                    charityAmountCents === charityDollarOption
                                }
                                onChange={() =>
                                    setCharityAmount(charityDollarOption)
                                }
                            />
                        );
                    })}
                    {charitySettings.mode === "round_up_and_dollars" ? (
                        <ToggleButton
                            className={styles.toggleButtonSmall}
                            checkedClassName={styles.toggleButtonSmallChecked}
                            label={strings.roundUp}
                            checked={shouldRoundUp}
                            onChange={() => setShouldRoundUp(true)}
                        />
                    ) : null}
                </div>
            </div>
        );
    };

    const renderQsrQuestion = (question: QsrQuestion) => {
        let checkedVal = utensilsChecked;
        let checkToggleFunc = setUtensilsChceked;
        if (question.value === "napkins") {
            checkedVal = napkinsChecked;
            checkToggleFunc = setNapkinsChecked;
        } else if (question.value === "ketchup") {
            checkedVal = ketchupChecked;
            checkToggleFunc = setKetchupChceked;
        }

        return (
            <div className={styles.questionContainer}>
                <div className={styles.question}>{question.question}</div>
                <div className={styles.optionsContainer}>
                    <ToggleButton
                        className={styles.toggleButton}
                        checkedClassName={styles.toggleButtonChecked}
                        label={strings.yes}
                        checked={checkedVal}
                        onChange={() => {
                            checkToggleFunc(true);
                        }}
                    />
                    <ToggleButton
                        className={styles.toggleButton}
                        checkedClassName={styles.toggleButtonChecked}
                        label={strings.no}
                        checked={!checkedVal}
                        onChange={() => {
                            checkToggleFunc(false);
                        }}
                    />
                </div>
            </div>
        );
    };

    const onAddCondiments = () => {
        dispatch(
            Actions.currentOrderActions.addItem(
                selectedItemId,
                condimentQuantity,
                {},
            ),
        );
        setSelectedItemId("");
        setCondimentQuantity(1);
    };

    const renderCondimentSection = () => {
        if (condimentProducts.length === 0) {
            return null;
        }

        return (
            <div>
                <div className={styles.question}>{strings.condimentPrompt}</div>
                <div className={styles.condimentButtonsContainer}>
                    {condimentProducts.slice(0, 6).map((condiment, i) => {
                        if (condiment.type !== "item") {
                            return null;
                        }

                        const item = itemsById[condiment.itemId];
                        const displayedName =
                            item.name.length > 16
                                ? item.name.slice(0, 13) + "..."
                                : item.name;
                        return (
                            <ToggleButton
                                className={styles.condimentToggleButton}
                                key={i}
                                label={displayedName}
                                checked={selectedItemId === condiment.itemId}
                                onChange={() => {
                                    setSelectedItemId(condiment.itemId);
                                }}
                            />
                        );
                    })}
                </div>
                <div className={styles.condimentAddToOrderBar}>
                    <QuantityControl
                        quantity={condimentQuantity}
                        setQuantity={setCondimentQuantity}
                    />
                    <OutlineButton
                        label={strings.addToOrder}
                        onClick={onAddCondiments}
                        disabled={!selectedItemId}
                    />
                </div>
            </div>
        );
    };

    const renderQsrQuestions = () => {
        let questions = DEFAULT_QUESTIONS;
        if (orderType === "in_store") {
            questions = HERE_QUESTIONS;
        }

        return (
            <div className={styles.questionsContainer}>
                {renderCharitySection()}
                {questions.map((q, i) => {
                    return <div key={i}>{renderQsrQuestion(q)}</div>;
                })}
                {renderCondimentSection()}
            </div>
        );
    };

    const onClickContinue = () => {
        const qsrSelections: posModels.QsrSelection[] = [];
        if (utensilsChecked) {
            qsrSelections.push("utensils");
        }
        if (orderType !== "in_store") {
            if (napkinsChecked) {
                qsrSelections.push("napkins");
            }
            if (ketchupChecked) {
                qsrSelections.push("ketchup");
            }
        }
        dispatch(posActions.currentOrderSetQsrSelections(qsrSelections));

        if (orderType === "drive_thru") {
            navigate(routeHelpers.checkoutDriveThru());
            return;
        }
        navigate(routeHelpers.checkout());
    };

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.orderTypeText}>
                    {orderTypeStrings[orderType ?? ""]}
                </div>
            </div>
            {renderQsrQuestions()}
            <div className={styles.continueToCheckoutContainer}>
                <OutlineButton
                    className={styles.continueToCheckout}
                    mode="blue"
                    label={strings.continueToCheckout}
                    onClick={onClickContinue}
                />
            </div>
        </div>
    );
}
