import {useAppSelector} from "../lib/hooks";
import * as routeHelpers from "../lib/routeHelpers";
import MenuProductLink from "./MenuProductLink";
import React from "react";

type Props = {
    className?: string;
    categoryId: string;
    comboId: string;
    menudId: string;
    numColumns: number;
    displaySize: "small" | "regular";
};

export default function MenuComboLink({
    displaySize = "regular",
    ...props
}: Props) {
    const combo = useAppSelector((state) => state.combos.byId[props.comboId]);
    const menuCombo = useAppSelector(
        (state) => state.menuCombos.byMenuId[props.menudId][props.comboId],
    );

    return (
        <MenuProductLink
            className={props.className}
            url={routeHelpers.combo(props.categoryId, combo.id)}
            name={combo.name}
            status={menuCombo.status}
            statusReason={menuCombo.statusReason}
            retinaImages={combo.isolatedImages352}
            numColumns={props.numColumns}
            displaySize={displaySize}
        />
    );
}
