import * as posActions from "../../lib/posActions";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import * as searchParamNames from "../../constants/searchParamNames";
import CheckoutItemOrCombo from "../CheckoutItemOrCombo";
import CheckoutProductBase from "../CheckoutProductBase";
import cn from "classnames";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import React, {useContext, useEffect, useRef, useState} from "react";
import SelectableCheckoutComboOrItem from "../SelectableCheckoutComboOrItem";
import SplitModal from "../SplitModal";
import styles from "./CashierCheckoutItems.module.css";
import usePrevious from "../../lib/usePrevious";
import {useAppSelector, useAppDispatch} from "../../lib/hooks";
import {Actions, API, Lib, Constants} from "habit-core";
import {
    Routes,
    Route,
    useNavigate,
    Outlet,
    useSearchParams,
} from "react-router-dom";
import {TillAssignmentContext} from "../TillAssignmentContext";
import {CashierModeContext} from "../CashierModeContext";
import ToggleButton from "../ToggleButton";

const strings = {
    toGo: "To-Go",
    dineIn: "Dine-In",
    proceed: "Proceed",
    total: "Total :",
    includesTax: "(Includes Tax)",
    editOrder: "Edit Order",
    noItems: "No Items in Order",
    giftCardValue: "Gift Card Value",
    giftCard: "Gift Card",
    proceedPayment: "Proceed To Payment",
    lane1: "Lane 1",
    lane2: "Lane 2",
};

type Props = {
    className?: string;
    submitDriveThruOrder: () => void;
    orderData: Lib.selectors.CartEntity[];
};

const MAX_QUANTITY = 99;

export default function CashierCheckoutItems(props: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const isRequestingCCPayment =
        searchParams.get(searchParamNames.REQUESTING_CC_PAYMENT) === "true";
    const {isTraining, errorAfterPaymentsProcessed} =
        useContext(CashierModeContext);
    const {assignedTill, trainingModeAssignedTill} = useContext(
        TillAssignmentContext,
    );

    const currentOrder = useAppSelector((state) => state.currentOrder);
    const orderEmpty =
        !currentOrder.comboCustomizationIds.length &&
        !currentOrder.itemCustomizationIds.length;
    const stationMode = useAppSelector((state) => state.pos.station.mode);
    const laneAssignment = useAppSelector(
        (state) => state.pos.station.laneAssignment,
    );
    const driveThruDetails = useAppSelector(
        (state) => state.pos.currentOrder.driveThruDetails,
    );

    const voidedItemIds = useAppSelector(
        (state) => state.pos.currentOrder.voidedItemIds,
    );
    const voidedItemsById = useAppSelector(
        (state) => state.pos.currentOrderVoided.items.voided.byId,
    );
    const voidedComboIds = useAppSelector(
        (state) => state.pos.currentOrder.voidedComboIds,
    );
    const voidedCombosById = useAppSelector(
        (state) => state.pos.currentOrderVoided.combos.voided.byId,
    );
    const pendingVoidedItemsById = useAppSelector(
        (state) => state.pos.currentOrderVoided.items.pending.byId,
    );
    const pendingVoidedItemIds = Object.keys(pendingVoidedItemsById);
    const pendingVoidedCombosById = useAppSelector(
        (state) => state.pos.currentOrderVoided.combos.pending.byId,
    );
    const pendingVoidedComboIds = Object.keys(pendingVoidedCombosById);

    const addFundsGiftCards = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.addFunds,
    );
    const purchaseGiftCards = useAppSelector(
        (state) => state.pos.currentOrder.giftCards.purchase,
    );
    // TODO: #261 - support purchasing gift cards as part of regular order
    const isGiftCardsOrder =
        !!addFundsGiftCards.length || !!purchaseGiftCards.length;

    const subtotalAndTaxCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTotalCents(state, []),
    );

    const discountCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderDiscountAmountCents(state),
    );

    const [splitProduct, setSplitProduct] = useState<{
        entity: Lib.selectors.CartEntityItemOrCombo;
        totalQuantity: number;
    } | null>(null);

    const orderTotalQuantity = useAppSelector((state) =>
        posSelectors.getCurrentOrderTotalQuantity(state),
    );
    const prevOrderTotalQuantity = usePrevious(orderTotalQuantity);
    const prevOrderLength = usePrevious(props.orderData.length);
    const bottomOfListRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // NOTE: only scroll when add a new item to the order
        // (not when split, which results in the order length changing but the order total quantity remaining the same)
        if (
            prevOrderLength !== undefined &&
            prevOrderTotalQuantity !== undefined &&
            prevOrderLength < props.orderData.length &&
            prevOrderTotalQuantity < orderTotalQuantity
        ) {
            bottomOfListRef.current?.scrollIntoView({behavior: "smooth"});
        }
    }, [props.orderData.length]);

    const selectOrderType = (orderType: API.models.OrderType) => {
        dispatch(
            // NOTE: this action returns modified items resulting from changes in menu selection,
            // but since to go and dine in use the same menu, we don't need the return value
            Actions.currentOrderActions.selectOrderType(orderType),
        );

        navigate(routeHelpers.preCheckout());
    };

    const onVoidCheckoutComboOrItem = (
        type: "item" | "combo",
        customizationId: string,
        quantity: number,
    ) => {
        if (type === "item") {
            dispatch(
                posActions.addPendingVoidedItem(customizationId, quantity),
            );
        } else if (type === "combo") {
            dispatch(
                posActions.addPendingVoidedCombo(customizationId, quantity),
            );
        }
    };

    const onDeselectVoidCheckoutComboOrItem = (
        type: "item" | "combo",
        customizationId: string,
    ) => {
        if (type === "item") {
            const id = pendingVoidedItemIds.find(
                (id) =>
                    pendingVoidedItemsById[id].itemCustomizationId ===
                    customizationId,
            );
            if (id) {
                dispatch(posActions.removePendingVoidedItem(id));
            } else {
                logger.warn(
                    `unable to find pending void item with customization id ${customizationId}`,
                );
            }
        } else if (type === "combo") {
            const id = pendingVoidedComboIds.find(
                (id) =>
                    pendingVoidedCombosById[id].comboCustomizationId ===
                    customizationId,
            );
            if (id) {
                dispatch(posActions.removePendingVoidedCombo(id));
            } else {
                logger.warn(
                    `unable to find pending void combo with customization id ${customizationId}`,
                );
            }
        }
    };

    const removeGiftCardValue = (giftCardId: string) => {
        dispatch(posActions.removeExistingGiftCardRequest(giftCardId));
    };

    const removeGiftCard = (giftCardId: string) => {
        dispatch(posActions.removeNewGiftCardRequest(giftCardId));
    };

    const updateGiftCard = (
        id: string,
        balanceCents: API.models.USDCents,
        quantity: number,
    ) => {
        dispatch(
            posActions.updateNewGiftCardRequest(id, balanceCents, quantity),
        );
    };

    function renderCashierButton() {
        const isTillUnassigned =
            (isTraining && trainingModeAssignedTill === -1) ||
            (!isTraining && assignedTill === -1);
        const disabled = orderEmpty || isTillUnassigned;

        if (isGiftCardsOrder) {
            return (
                <OutlineButton
                    className={styles.footerButton}
                    label={strings.proceedPayment}
                    onClick={() => navigate(routeHelpers.payment())}
                />
            );
        }

        if (
            stationMode === "front_of_house_1" ||
            stationMode === "front_of_house_2"
        ) {
            return (
                <>
                    <OutlineButton
                        className={cn(styles.footerButton, styles.togo)}
                        label={strings.toGo}
                        onClick={() =>
                            selectOrderType(Constants.orderType.TO_GO)
                        }
                        disabled={disabled}
                    />

                    <OutlineButton
                        className={styles.footerButton}
                        label={strings.dineIn}
                        onClick={() =>
                            selectOrderType(Constants.orderType.DINE_IN)
                        }
                        disabled={disabled}
                    />
                </>
            );
        }

        if (
            stationMode === "line_buster" ||
            stationMode === "drive_thru_order_taker"
        ) {
            return (
                <OutlineButton
                    className={styles.footerButton}
                    label={strings.proceed}
                    onClick={() =>
                        selectOrderType(Constants.orderType.DRIVE_THRU)
                    }
                    disabled={disabled}
                />
            );
        }

        if (stationMode === "drive_thru_order_fulfillment") {
            return (
                <OutlineButton
                    className={styles.footerButton}
                    label={strings.proceed}
                    onClick={() => navigate(routeHelpers.payment())}
                    disabled={disabled}
                />
            );
        }

        return null;
    }

    function renderDefaultFooter() {
        // we are guaranteed that the station mode is drive thru order taker or lane buster
        if (laneAssignment === "both") {
            return (
                <div className={styles.laneChoiceAndFooterButtonContainer}>
                    {laneAssignment === "both" ? (
                        <div className={styles.laneChoiceContainer}>
                            <ToggleButton
                                className={styles.laneChoiceButton}
                                label={strings.lane1}
                                disabled={assignedTill === -1}
                                checked={driveThruDetails.laneNumber === 1}
                                onChange={() => {
                                    dispatch(
                                        posActions.setCurrentOrderDriveThruDetails(
                                            {
                                                ...driveThruDetails,
                                                laneNumber: 1,
                                            },
                                        ),
                                    );
                                }}
                            />
                            <ToggleButton
                                className={styles.laneChoiceButton}
                                label={strings.lane2}
                                disabled={assignedTill === -1}
                                checked={driveThruDetails.laneNumber === 2}
                                onChange={() => {
                                    dispatch(
                                        posActions.setCurrentOrderDriveThruDetails(
                                            {
                                                ...driveThruDetails,
                                                laneNumber: 2,
                                            },
                                        ),
                                    );
                                }}
                            />
                        </div>
                    ) : null}
                    <div
                        className={cn(
                            styles.footerButtonsContainer,
                            !orderEmpty &&
                                stationMode !== "drive_thru_order_fulfillment"
                                ? styles.blueBackground
                                : null,
                        )}>
                        {renderCashierButton()}
                    </div>
                </div>
            );
        }

        return (
            <div
                className={cn(
                    styles.footerButtonsContainer,
                    !orderEmpty &&
                        stationMode !== "drive_thru_order_fulfillment"
                        ? styles.blueBackground
                        : null,
                )}>
                {renderCashierButton()}
            </div>
        );
    }

    function renderDefaultTotalContent() {
        return (
            <div className={styles.totalContainer}>
                <div
                    className={cn(
                        styles.totalText,
                        location.pathname === routeHelpers.orderComplete()
                            ? styles.totalTextGreyed
                            : null,
                    )}>
                    <div>{strings.total}</div>
                    <div>{strings.includesTax}</div>
                </div>

                <div
                    className={cn(
                        styles.totalAmount,
                        location.pathname === routeHelpers.orderComplete()
                            ? styles.totalTextGreyed
                            : null,
                    )}>
                    {Lib.currency.centsToDollarString(
                        subtotalAndTaxCents - discountCents < 0
                            ? 0
                            : subtotalAndTaxCents - discountCents,
                    )}
                </div>
            </div>
        );
    }

    function renderVerticallyAlignedTotal() {
        return (
            <div className={styles.verticallyAlignedTotalContainer}>
                <div
                    className={cn(
                        styles.totalText,
                        location.pathname === routeHelpers.orderComplete()
                            ? styles.totalTextGreyed
                            : null,
                    )}>
                    {strings.total + " " + strings.includesTax}
                </div>
                <div
                    className={cn(
                        styles.totalAmount,
                        styles.noMargin,
                        location.pathname === routeHelpers.orderComplete()
                            ? styles.totalTextGreyed
                            : null,
                    )}>
                    {Lib.currency.centsToDollarString(
                        subtotalAndTaxCents - discountCents < 0
                            ? 0
                            : subtotalAndTaxCents - discountCents,
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <div
                className={cn(
                    styles.dataContainer,
                    splitProduct && styles.noScroll,
                    props.className,
                )}>
                <div className={styles.innerDataContainer}>
                    {props.orderData.map((x, i) => {
                        if (x.type === "item" || x.type === "combo") {
                            const productProps = {
                                className: styles.dataBorder,
                                entity: x,
                                onPressSplit: (
                                    entity: Lib.selectors.CartEntityItemOrCombo,
                                    totalQuantity: number,
                                ) => setSplitProduct({entity, totalQuantity}),
                            };

                            let voidId = null;
                            if (x.type === "item") {
                                voidId = voidedItemIds.find(
                                    (id) =>
                                        voidedItemsById[id]
                                            .itemCustomizationId ===
                                        x.customizationId,
                                );
                            } else if (x.type === "combo") {
                                voidId = voidedComboIds.find(
                                    (id) =>
                                        voidedCombosById[id]
                                            .comboCustomizationId ===
                                        x.customizationId,
                                );
                            }

                            return (
                                <Routes key={x.customizationId}>
                                    <Route
                                        path="*"
                                        element={
                                            <>
                                                <CheckoutItemOrCombo
                                                    {...productProps}
                                                    editable="editable"
                                                />
                                                {voidId ? (
                                                    <CheckoutItemOrCombo
                                                        {...productProps}
                                                        voidId={voidId}
                                                        editable="editable"
                                                    />
                                                ) : null}
                                            </>
                                        }
                                    />

                                    <Route
                                        path="/checkout/*"
                                        element={
                                            <>
                                                <CheckoutItemOrCombo
                                                    {...productProps}
                                                    editable={
                                                        isRequestingCCPayment ||
                                                        errorAfterPaymentsProcessed ||
                                                        stationMode ===
                                                            "drive_thru_order_fulfillment"
                                                            ? "uneditable"
                                                            : "removable"
                                                    }
                                                />
                                                {voidId ? (
                                                    <CheckoutItemOrCombo
                                                        {...productProps}
                                                        voidId={voidId}
                                                        editable={
                                                            isRequestingCCPayment ||
                                                            errorAfterPaymentsProcessed ||
                                                            stationMode ===
                                                                "drive_thru_order_fulfillment"
                                                                ? "uneditable"
                                                                : "removable"
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        }
                                    />

                                    <Route
                                        path="/checkout/complete"
                                        element={
                                            <>
                                                <CheckoutItemOrCombo
                                                    {...productProps}
                                                    editable="uneditable"
                                                />
                                                {voidId ? (
                                                    <CheckoutItemOrCombo
                                                        {...productProps}
                                                        voidId={voidId}
                                                        editable="uneditable"
                                                    />
                                                ) : null}
                                            </>
                                        }
                                    />

                                    <Route
                                        path="/manager/void"
                                        element={
                                            <SelectableCheckoutComboOrItem
                                                className={
                                                    i !== 0
                                                        ? styles.selectableItem
                                                        : undefined
                                                }
                                                entity={x}
                                                selected={
                                                    (x.type === "item" &&
                                                        !!pendingVoidedItemIds.find(
                                                            (id) =>
                                                                pendingVoidedItemsById[
                                                                    id
                                                                ]
                                                                    .itemCustomizationId ===
                                                                x.customizationId,
                                                        )) ||
                                                    (x.type === "combo" &&
                                                        !!pendingVoidedComboIds.find(
                                                            (id) =>
                                                                pendingVoidedCombosById[
                                                                    id
                                                                ]
                                                                    .comboCustomizationId ===
                                                                x.customizationId,
                                                        ))
                                                }
                                                onSelect={
                                                    onVoidCheckoutComboOrItem
                                                }
                                                onDeSelect={
                                                    onDeselectVoidCheckoutComboOrItem
                                                }
                                                disabled={!!voidId}
                                            />
                                        }
                                    />
                                </Routes>
                            );
                        } else if (x.type === "discount") {
                            // TODO: #91 - discount row item
                            return null;
                        }

                        return null;
                    })}
                    {addFundsGiftCards.map((r) => {
                        const baseProps = {
                            className: styles.dataBorder,
                            name: strings.giftCardValue,
                            quantity: 1,
                            formattedPrice: Lib.currency.centsToDollarString(
                                r.amountToAddCents,
                            ),
                            editTo: routeHelpers.giftCardsAddFunds(r.id),
                            onRemove: () => removeGiftCardValue(r.id),
                        };
                        return (
                            <Routes key={r.id}>
                                <Route
                                    path="*"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable="editable"
                                        />
                                    }
                                />

                                <Route
                                    path="/checkout/*"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable={
                                                isRequestingCCPayment ||
                                                errorAfterPaymentsProcessed
                                                    ? "uneditable"
                                                    : "removable"
                                            }
                                        />
                                    }
                                />
                                <Route
                                    path="/checkout/swipe-gift-card"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable="uneditable"
                                        />
                                    }
                                />
                                <Route
                                    path="/checkout/complete"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable="uneditable"
                                        />
                                    }
                                />

                                <Route path="/manager/void" element={null} />
                            </Routes>
                        );
                    })}
                    {purchaseGiftCards.map((gc) => {
                        const baseProps = {
                            className: styles.dataBorder,
                            name: strings.giftCard,
                            quantity: gc.quantity,
                            onChangeQuantity: (quantity: number) =>
                                updateGiftCard(
                                    gc.id,
                                    gc.balanceCents,
                                    quantity,
                                ),
                            canIncreaseQuantity: gc.quantity < MAX_QUANTITY,
                            canDecreaseQuantity: gc.quantity > 1,
                            formattedPrice: Lib.currency.centsToDollarString(
                                gc.balanceCents,
                            ),
                            editTo: routeHelpers.giftCardsPurchase(gc.id),
                            onRemove: () => removeGiftCard(gc.id),
                        };
                        return (
                            <Routes key={gc.id}>
                                <Route
                                    path="*"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable="editable"
                                        />
                                    }
                                />

                                <Route
                                    path="/checkout/*"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable={
                                                isRequestingCCPayment ||
                                                errorAfterPaymentsProcessed
                                                    ? "uneditable"
                                                    : "removable"
                                            }
                                        />
                                    }
                                />

                                <Route
                                    path="/checkout/swipe-gift-card"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable="uneditable"
                                        />
                                    }
                                />

                                <Route
                                    path="/checkout/complete"
                                    element={
                                        <CheckoutProductBase
                                            {...baseProps}
                                            editable="uneditable"
                                        />
                                    }
                                />

                                <Route path="/manager/void" element={null} />
                            </Routes>
                        );
                    })}
                    {/* TODO: #254 - better route handling (hide gift cards when not on gift card specific routes) */}
                    {!isGiftCardsOrder && !props.orderData.length ? (
                        <div className={styles.emptyOrder}>
                            {strings.noItems}
                        </div>
                    ) : null}
                    {splitProduct ? (
                        <SplitModal
                            overlayClassName={styles.splitOverlay}
                            productName={splitProduct.entity.name}
                            totalQuantity={splitProduct.totalQuantity}
                            onClose={() => setSplitProduct(null)}
                            onSplit={(newQuantity: number) => {
                                if (!splitProduct) {
                                    logger.warn("trying to split null product");
                                    return;
                                }

                                if (splitProduct.entity.type === "item") {
                                    dispatch(
                                        Actions.currentOrderActions.splitItem(
                                            splitProduct.entity.customizationId,
                                            newQuantity,
                                        ),
                                    );
                                    setSplitProduct(null);
                                } else if (
                                    splitProduct.entity.type === "combo"
                                ) {
                                    dispatch(
                                        Actions.currentOrderActions.splitCombo(
                                            splitProduct.entity.customizationId,
                                            newQuantity,
                                        ),
                                    );
                                    setSplitProduct(null);
                                } else {
                                    logger.warn(
                                        `split not supported for entity type ${splitProduct.entity.type}`,
                                    );
                                }
                            }}
                        />
                    ) : null}
                    <div ref={bottomOfListRef} />
                </div>
            </div>

            <div
                className={cn(
                    styles.footerContainer,
                    laneAssignment === "both" &&
                        styles.footerContainerWithLaneAssignments,
                )}>
                <Routes>
                    <Route path="*" element={renderDefaultFooter()} />
                    <Route path="checkout" element={<Outlet />}>
                        <Route
                            path="*"
                            element={
                                <div className={styles.footerButtonsContainer}>
                                    <OutlineButton
                                        className={styles.footerButton}
                                        label={strings.editOrder}
                                        onClick={() =>
                                            navigate(
                                                isGiftCardsOrder
                                                    ? routeHelpers.giftCards()
                                                    : "/",
                                            )
                                        }
                                        disabled={
                                            location.pathname ===
                                                routeHelpers.giftCardPurchase() ||
                                            isRequestingCCPayment ||
                                            errorAfterPaymentsProcessed
                                        }
                                    />
                                </div>
                            }
                        />
                        <Route
                            path="complete"
                            element={
                                <div className={styles.footerButtonsContainer}>
                                    <OutlineButton
                                        className={styles.footerButton}
                                        disabled={true}
                                        label={strings.editOrder}
                                        onClick={() => navigate("/")}
                                    />
                                </div>
                            }
                        />
                    </Route>
                </Routes>

                <Routes>
                    <Route
                        path="*"
                        element={
                            laneAssignment === "both"
                                ? renderVerticallyAlignedTotal()
                                : renderDefaultTotalContent()
                        }
                    />
                    <Route
                        path="checkout"
                        element={renderDefaultTotalContent()}
                    />
                    <Route
                        path="complete"
                        element={renderDefaultTotalContent()}
                    />
                </Routes>
            </div>
        </>
    );
}
