import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import ConfirmationModal from "../ConfirmationModal";
import ManagerPermissionForm from "../ManagerPermissionForm";
import OutlineButton from "../OutlineButton";
import React, {useContext, useState} from "react";
import styles from "./ManagerHome.module.css";
import {CashierModeContext} from "../CashierModeContext";
import {useAppSelector} from "../../lib/hooks";

function getValueFromMode(mode: posModels.StationMode): string {
    switch (mode) {
        case "front_of_house_1":
            return "Front of House 1";
        case "front_of_house_2":
            return "Front of House 2";
        case "drive_thru_order_taker":
            return "Drive Thru - Order Taker";
        case "drive_thru_order_fulfillment":
            return "Drive Thru - Order Fulfillment";
        case "line_buster":
            return "Line Buster";
    }
}

function getValueFromLaneAssignment(
    laneAssigment: posModels.LaneAssignment,
): string {
    switch (laneAssigment) {
        case "lane_1":
            return "1";
        case "lane_2":
            return "2";
        case "both":
            return "Both";
    }
}

const strings = {
    welcome: "Welcome Back,",
    restart: "Restart POS",
    confirmationModalTitle: "Are you sure you want to restart the POS?",
    confirmLabel: "Yes, restart",
    cancelLabel: "Cancel",
    stationModeLabel: "Order Mode: ",
    laneAssignmentLabel: "Lane Selection: ",
};

export default function ManagerHome() {
    const {cashierMode, setCashierMode} = useContext(CashierModeContext);
    const posStation = useAppSelector((state) => state.pos.station);
    const [restartConfirmationOpen, setRestartConfirmationOpen] =
        useState(false);

    return (
        <div className={styles.container}>
            <div className={styles.modeAndRestartContainer}>
                <div className={styles.modeDetailsContainer}>
                    <div>
                        <span className={styles.modeDetailsLabel}>
                            {strings.stationModeLabel}
                        </span>
                        <span className={styles.modeDetailsValue}>
                            {getValueFromMode(posStation.mode)}
                        </span>
                    </div>
                    {posStation.mode === "drive_thru_order_taker" &&
                    posStation.laneAssignment !== null ? (
                        <div className={styles.laneAssignmentInfo}>
                            <span className={styles.modeDetailsLabel}>
                                {strings.laneAssignmentLabel}
                            </span>
                            <span className={styles.modeDetailsValue}>
                                {getValueFromLaneAssignment(
                                    posStation.laneAssignment,
                                )}
                            </span>
                        </div>
                    ) : null}
                </div>
                <OutlineButton
                    label={strings.restart}
                    onClick={() => setRestartConfirmationOpen(true)}
                />
            </div>
            {cashierMode.type === "manager" ? (
                <div className={styles.welcome}>
                    {strings.welcome} {cashierMode.firstName}.
                </div>
            ) : (
                <ManagerPermissionForm
                    showReturnPrompt={true}
                    onSuccess={(data) =>
                        setCashierMode({
                            managerId: data.id,
                            type: "manager",
                            firstName: data.firstName,
                            lastName: data.lastName,
                        })
                    }
                />
            )}
            <ConfirmationModal
                isOpen={restartConfirmationOpen}
                title={strings.confirmationModalTitle}
                confirmLabel={strings.confirmLabel}
                cancelLabel={strings.cancelLabel}
                onPressConfirm={() => {
                    location.pathname = routeHelpers.menu();
                }}
                onPressCancel={() => {
                    setRestartConfirmationOpen(false);
                }}
            />
        </div>
    );
}
