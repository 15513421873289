import * as paymentMethods from "../../lib/api/paymentMethods";
import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as posSelectors from "../../lib/posSelectors";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import React, {useState, useEffect, useContext, useRef} from "react";
import Spinner from "../Spinner";
import styles from "./OrderSubtotalLayout.module.css";
import trashIcon from "../../images/trash.svg";
import {AxiosError} from "axios";
import {Actions, API, Constants, Lib} from "habit-core";
import {
    Outlet,
    useOutletContext,
    useLocation,
    useNavigate,
} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import ErrorModal from "../ErrorModal";
import {ParallelModeContext} from "../ParallelModeContext";
import {CashierModeContext} from "../CashierModeContext";
import {openCashDrawer} from "../../lib/CashDrawer/CashDrawerPlugin";

const strings = {
    subtotal: "Subtotal :",
    tax: "Tax :",
    tip: "Tip :",
    orderTotal: "Order Total :",
    balanceDue: "Balance Due :",
    charity: "Charity :",
    cashPaid: "Cash :",
    changeOwed: "Change Owed :",
    discountApplied: "Discount Applied: ",
    removeDiscount: "Remove Discount",
    giftCard: "Gift Card :",
    compCard: "Comp Card :",
    balance: "Balance :",
    creditCard: "Credit Card",
    removeGiftCard: "Remove Gift Card",
    removeCompCard: "Remove Comp Card",
    orderComplete: "Order Completed",
    addFundsInstructions: "Your gift card balance has been updated.",
    instructions:
        "Your pager will light up and vibrate when your order is ready. Thank you!",
    returnChange: "Please return guest's change",
    knowBalance: "Ask If They’d Like To Know Their Balance",
    giftCardProcessed: "Card Successfully Processed.",
    giveCard: "Hand the gift card(s) to the guest.",
    errorAfterPaymentsProcessedMessage: (orderId: string) =>
        `Payment was successfully captured, but this order failed to process and was not sent to the kitchen. Please note the order contents and send them manually to the kitchen.\n\nOrder Id: ${orderId}`,
    placeOrder: "Place Order",
    nextOrder: "Next Order",
    fundraiserApplied: "Fundraiser Applied",
    removeFundraiser: "Remove Fundraiser",
    ezCater: "Ez Cater",
};

function maskCardNumber(cardNumber: string): string {
    return `${"*".repeat(cardNumber.length - 4)}${cardNumber.slice(-4)}`;
}

function maskGiftCardNumber(cardNumber: string): string {
    return `${"*".repeat(cardNumber.length - 6)}${cardNumber.slice(-6)}`;
}

type NameState = {
    name?: string;
};

type PaidContext = {
    balanceDueCents: API.models.USDCents;
    orderTotalCents: API.models.USDCents;
    taxCents: API.models.USDCents;
    paidCents: API.models.USDCents;
    setGiftCardNumbersRequired: (val: boolean) => void;
    tempDiscountCents: API.models.USDCents;
    setTempDiscountCents: (discountAmountCents: API.models.USDCents) => void;
};
export function usePaidContext() {
    return useOutletContext<PaidContext>();
}

export default function OrderSubtotalLayout() {
    const {isParallel} = useContext(ParallelModeContext);
    const isDriveThruFulfillmentMode =
        useAppSelector((state) => state.pos.station.mode) ===
        "drive_thru_order_fulfillment";
    const {
        errorAfterPaymentsProcessed,
        isTraining,
        setErrorAfterPaymentsProcessed,
    } = useContext(CashierModeContext);
    const dispatch = useAppDispatch();
    const cashPaidCents = useAppSelector(
        (state) => state.pos.currentOrder.payment.cashPaidCents,
    );
    const changeOwedCents = useAppSelector(
        (state) => state.pos.currentOrder.payment.changeOwedCents,
    );
    const giftCardsApplied = useAppSelector(
        (state) => state.pos.currentOrder.payment.giftCardsApplied,
    );
    const compCardsApplied = useAppSelector(
        (state) => state.pos.currentOrder.payment.compCardsApplied,
    );
    const creditCardApplied = useAppSelector(
        (state) => state.pos.currentOrder.payment.creditCardApplied,
    );
    const fundraiserCodeApplied =
        useAppSelector((state) => state.currentOrder.fundraiserCode) !== null;

    const staffTipCents: API.models.USDCents | null =
        creditCardApplied && creditCardApplied.tipAmountCents !== 0
            ? creditCardApplied.tipAmountCents
            : null;

    const posCurrentOrder = useAppSelector((state) => state.pos.currentOrder);

    const currentOrderEmpty = useAppSelector(
        (state) =>
            !state.currentOrder.comboCustomizationIds.length &&
            !state.currentOrder.itemCustomizationIds.length &&
            !state.pos.currentOrder.giftCards.purchase.length &&
            !state.pos.currentOrder.giftCards.addFunds.length,
    );
    const subtotalCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderSubtotalSansVoidedCents(state),
    );
    const charityEnabled = useAppSelector(
        (state) => state.pos.charitySettings.isEnabled,
    );
    const charityRoundUpAmountCents = useAppSelector((state) =>
        posSelectors.getCharityRoundUpSansVoidedCents(state, compCardsApplied),
    );
    const charityAmountCents = useAppSelector(
        (state) => state.pos.currentOrder.charityAmountCents,
    );
    const appliedCharityAmountCents = !charityAmountCents
        ? charityRoundUpAmountCents
        : charityAmountCents;
    const taxCents = useAppSelector((state) =>
        posSelectors.getCurrentOrderTaxSansVoidedCents(state, compCardsApplied),
    );
    const discounts = useAppSelector(
        (state) => state.pos.currentOrder.discounts,
    );
    const discountAmountCents = Math.min(
        useAppSelector((state) =>
            posSelectors.getCurrentOrderDiscountAmountCents(state),
        ),
        subtotalCents,
    );
    const [tempDiscountCents, setTempDiscountCents] = useState(0);

    const orderType = useAppSelector(
        (state) => state.currentOrder.orderType ?? Constants.orderType.DINE_IN,
    );

    const giftCardsToPurchase = posCurrentOrder.giftCards.purchase;
    const giftCardsToAddFunds = posCurrentOrder.giftCards.addFunds;

    const orderTotalCents =
        subtotalCents + appliedCharityAmountCents + taxCents;
    const giftCardPaidCents = giftCardsApplied.reduce(
        (sum, gc) => gc.appliedCents + sum,
        0,
    );
    const compCardPaidCents = compCardsApplied.reduce(
        (sum, cc) => (cc.appliedCents ?? 0) + sum,
        0,
    );
    const creditCardPaidCents = creditCardApplied?.appliedAmountCents ?? 0;
    const ezCaterApplied = posCurrentOrder.payment.ezCaterApplied;
    const paidCents = ezCaterApplied
        ? orderTotalCents
        : cashPaidCents +
          discountAmountCents +
          giftCardPaidCents +
          compCardPaidCents +
          creditCardPaidCents;

    const balanceDueCents =
        paidCents > orderTotalCents ? 0 : orderTotalCents - paidCents;

    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;
    const [errorMessage, setErrorMessage] = useState("");
    const [submittingOrder, setSubmittingOrder] = useState(false);
    const [fullyPaidOrderPlaced, setFullyPaidOrderPlaced] = useState(false);
    const [orderSubmitted, setOrderSubmitted] = useState(false);
    const [giftCardNumbersRequired, setGiftCardNumbersRequired] = useState(
        giftCardsToPurchase.length > 0,
    );

    const mounted = useRef(false);

    useEffect(() => {
        // prevent automatic submission of order on first render of the order subtotal layout component
        // if user edits order such that total already paid > order total, when we get back to Checkout Step 1, we don't want to automatically submit the order.
        if (!mounted.current) {
            mounted.current = true;
            return;
        }

        if (balanceDueCents > 0 || discountAmountCents > orderTotalCents) {
            return;
        }

        if (!posCurrentOrder.id || !posCurrentOrder.startTime) {
            if (
                cashPaidCents +
                    giftCardPaidCents +
                    compCardPaidCents +
                    creditCardPaidCents >
                0
            ) {
                dispatch(posActions.clearPayments());
            }
            return;
        }

        if (giftCardNumbersRequired) {
            navigate(routeHelpers.giftCardPurchase());
        } else {
            processPaymentAndPlaceOrder();
        }
    }, [
        posCurrentOrder.id,
        posCurrentOrder.startTime,
        cashPaidCents,
        creditCardPaidCents,
        giftCardPaidCents,
        compCardPaidCents,
        discountAmountCents,
        balanceDueCents,
        orderTotalCents,
        giftCardNumbersRequired,
    ]);

    const processPaymentAndPlaceOrder = async () => {
        if (
            currentOrderEmpty &&
            !giftCardsToPurchase.length &&
            !giftCardsToAddFunds.length
        ) {
            return;
        }

        setSubmittingOrder(true);
        try {
            const orderTotalInclusiveOfTip =
                orderTotalCents + (staffTipCents ?? 0);
            const tempChangeOwedCents =
                paidCents > orderTotalInclusiveOfTip
                    ? paidCents - orderTotalInclusiveOfTip
                    : 0;
            const cashDetails: posModels.PaymentDetail[] =
                cashPaidCents !== 0
                    ? [
                          {
                              type: "cash",
                              amountCents: cashPaidCents,
                              changeOwedCents: tempChangeOwedCents,
                          },
                      ]
                    : [];

            const creditCardDetails: posModels.PaymentDetail[] =
                creditCardApplied
                    ? [
                          {
                              type: "credit",
                              maskedCardNumber: maskCardNumber(
                                  creditCardApplied.cardNumber,
                              ),
                              authId: creditCardApplied.authId,
                              termId: creditCardApplied.termId,
                              merchId: creditCardApplied.merchId,
                              amountCents: creditCardApplied.appliedAmountCents,
                              ccType: creditCardApplied.ccType,
                              cardholderName: creditCardApplied.cardholderName,
                              cardExpMonth: creditCardApplied.cardExpMonth,
                              cardExpYear: creditCardApplied.cardExpYear,
                              cardToken: creditCardApplied.cardToken,
                              ctroutd: creditCardApplied.ctroutd,
                              troutd: creditCardApplied.troutd,
                              tipCents: staffTipCents ?? 0,
                          },
                      ]
                    : [];

            const giftCardDetails: posModels.PaymentDetail[] = [];
            for (const gc of giftCardsApplied) {
                let balanceCents = 0;
                let authId = "";
                let cardToken = "";
                if (isParallel) {
                    balanceCents = gc.balanceRemainingCents;
                } else {
                    const response = await paymentMethods.reduceGiftCardBalance(
                        posCurrentOrder.id ?? "",
                        gc.id,
                        gc.appliedCents,
                    );
                    balanceCents = response.balanceCents;
                    authId = response.authId;
                    cardToken = response.cardToken;
                }

                giftCardDetails.push({
                    type: "gift_card",
                    maskedCardNumber: maskGiftCardNumber(gc.id),
                    amountCents: gc.appliedCents,
                    balanceCents,
                    cardToken,
                    authId,
                });
            }

            const compCardsDetails: posModels.PaymentDetail[] = [];
            for (const cc of compCardsApplied) {
                let balanceCents = 0;
                let authId = "";
                let cardToken = "";
                if (isParallel) {
                    balanceCents = cc.balance - (cc.appliedCents ?? 0);
                } else {
                    const response = await paymentMethods.reduceGiftCardBalance(
                        posCurrentOrder.id ?? "",
                        cc.id,
                        cc.appliedCents ?? 0,
                    );
                    balanceCents = response.balanceCents;
                    authId = response.authId;
                    cardToken = response.cardToken;
                }

                giftCardDetails.push({
                    type: "comp_card",
                    maskedCardNumber: maskGiftCardNumber(cc.id),
                    amountCents: cc.appliedCents ?? 0,
                    balanceCents,
                    authId,
                    cardToken,
                });
            }

            const paymentDetails: posModels.PaymentDetail[] = [
                ...cashDetails,
                ...giftCardDetails,
                ...compCardsDetails,
                ...creditCardDetails,
            ];

            if (ezCaterApplied) {
                paymentDetails.push({
                    type: "ez_cater",
                    amountCents: orderTotalCents - discountAmountCents,
                });
            }

            try {
                await dispatch(
                    posActions.reserveCurrentOrderRefNumIfNeeded(isTraining),
                );
            } catch (err) {
                setErrorMessage(
                    strings.errorAfterPaymentsProcessedMessage(
                        posCurrentOrder.id ?? "",
                    ),
                );
                setErrorAfterPaymentsProcessed(true);
                setSubmittingOrder(false);
                return;
            }

            try {
                if (cashPaidCents !== 0) {
                    await openCashDrawer(isTraining);
                }
                // eslint-disable-next-line no-empty
            } catch {} // error is already logged with sentry and we want to allow the purchase to go through.

            const pagerNumber = parseInt(customerName ?? "");
            try {
                await dispatch(
                    posActions.placeCurrentOrder(
                        isNaN(pagerNumber) ? customerName : null,
                        isNaN(pagerNumber) ? null : pagerNumber,
                        orderType === "drive_thru",
                        appliedCharityAmountCents,
                        subtotalCents,
                        staffTipCents,
                        taxCents,
                        orderTotalCents + (staffTipCents ?? 0),
                        "paid",
                        orderType === "drive_thru"
                            ? posCurrentOrder.driveThruDetails
                            : null,
                        paymentDetails,
                    ),
                );
            } catch (err) {
                setErrorMessage(
                    strings.errorAfterPaymentsProcessedMessage(
                        posCurrentOrder.id ?? "",
                    ),
                );
                setErrorAfterPaymentsProcessed(true);
                setSubmittingOrder(false);
                return;
            }

            if (tempChangeOwedCents) {
                dispatch(
                    posActions.setPaymentChangeOwedCents(tempChangeOwedCents),
                );
            }
            setOrderSubmitted(true);
            setSubmittingOrder(false);

            // TODO: #261 - support purchasing gift cards as part of regular order
            let header: string | undefined = undefined;
            if (location.pathname === routeHelpers.giftCardPayment()) {
                header = strings.giftCardProcessed;
            }

            let orderCompleteInstruction = isDriveThruFulfillmentMode
                ? undefined
                : strings.instructions;
            let subheader = "";
            if (tempChangeOwedCents !== 0) {
                subheader = `${
                    strings.returnChange
                } ${Lib.currency.centsToDollarString(tempChangeOwedCents)}.`;
            } else {
                const gc = giftCardsApplied.find(
                    (gc) => gc.balanceRemainingCents > 0,
                );
                const cc = compCardsApplied.find(
                    (cc) => cc.balance - (cc.appliedCents ?? 0) > 0,
                );
                let remainingCents: null | API.models.USDCents = null;
                if (gc) {
                    remainingCents = gc.balanceRemainingCents;
                } else if (cc) {
                    remainingCents = cc.balance - (cc.appliedCents ?? 0);
                }

                if (remainingCents) {
                    subheader = `${
                        strings.knowBalance
                    } (${Lib.currency.centsToDollarString(remainingCents)}).`;
                }

                if (giftCardsToPurchase.length) {
                    orderCompleteInstruction = strings.giveCard;
                } else if (giftCardsToAddFunds.length) {
                    orderCompleteInstruction = strings.addFundsInstructions;
                }
            }

            navigate(routeHelpers.orderComplete(), {
                state: {
                    header,
                    subheader,
                    subheaderClassName:
                        tempChangeOwedCents !== 0
                            ? styles.changeOwedOrderCompleteSubheader
                            : null,
                    instructions: orderCompleteInstruction,
                },
            });
        } catch (err) {
            logger.warn(err);
            const description = describeNetworkError(err as AxiosError);
            setErrorMessage(description.join("\n"));
            setSubmittingOrder(false);
        }
    };

    const onSubmitFullyCoveredOrder = () => {
        if (discountAmountCents < orderTotalCents) {
            return;
        }

        setSubmittingOrder(true);

        dispatch(posActions.reserveCurrentOrderRefNumIfNeeded(isTraining))
            .then(() => {
                const pagerNumber = parseInt(customerName ?? "");
                dispatch(
                    posActions.placeCurrentOrder(
                        isNaN(pagerNumber) ? customerName : null,
                        isNaN(pagerNumber) ? null : pagerNumber,
                        orderType === "drive_thru",
                        appliedCharityAmountCents,
                        subtotalCents,
                        staffTipCents,
                        taxCents,
                        orderTotalCents,
                        "paid",
                        orderType === "drive_thru"
                            ? posCurrentOrder.driveThruDetails
                            : null,
                        [],
                    ),
                )
                    .then(() => {
                        // TODO: #73 - Pass correct params depending on order / payment type
                        setFullyPaidOrderPlaced(true);
                        setOrderSubmitted(true);
                        navigate(routeHelpers.orderComplete(), {
                            state: {
                                instructions: isDriveThruFulfillmentMode
                                    ? undefined
                                    : strings.instructions,
                            },
                        });
                    })
                    .catch((err) => {
                        setErrorMessage(describeNetworkError(err).join("\n"));
                    });
            })
            .catch((err) => {
                // TODO: if this errors, how to recover?
                setErrorMessage(describeNetworkError(err).join("\n"));
            })
            .finally(() => {
                setSubmittingOrder(false);
            });
    };

    // these should be valid string values here
    const storeId = useAppSelector((state) => state.currentOrder.storeId);

    const clearAndStartNewOrder = () => {
        dispatch(posActions.clearCurrentOrder(storeId ?? ""));
        dispatch(posActions.reserveCurrentOrderId(isTraining));
        setErrorAfterPaymentsProcessed(false);
        navigate(routeHelpers.menu());
    };

    const getManualProceedButton = () => {
        if (
            (balanceDueCents ||
                currentOrderEmpty ||
                fullyPaidOrderPlaced ||
                orderSubmitted) &&
            !errorAfterPaymentsProcessed
        ) {
            return null;
        }

        let label = strings.placeOrder;
        let onClick =
            discountAmountCents > orderTotalCents
                ? onSubmitFullyCoveredOrder
                : processPaymentAndPlaceOrder;
        if (errorAfterPaymentsProcessed) {
            label = strings.nextOrder;
            onClick = clearAndStartNewOrder;
        }

        return (
            <OutlineButton
                label={label}
                onClick={onClick}
                loading={submittingOrder}
            />
        );
    };

    return (
        <>
            <div className={cn(styles.bordered, styles.left)}>
                {submittingOrder ? (
                    <div className={styles.spinner}>
                        <Spinner />
                    </div>
                ) : (
                    <Outlet
                        context={{
                            balanceDueCents,
                            orderTotalCents,
                            taxCents,
                            paidCents,
                            setGiftCardNumbersRequired,
                            tempDiscountCents,
                            setTempDiscountCents,
                        }}
                    />
                )}
            </div>

            <div className={cn(styles.bordered, styles.right)}>
                <div className={styles.row}>
                    <span>{strings.subtotal}</span>
                    <span>
                        {Lib.currency.centsToDollarString(
                            Math.max(
                                0,
                                subtotalCents -
                                    discountAmountCents -
                                    compCardPaidCents,
                            ),
                        )}
                    </span>
                </div>

                <div className={styles.row}>
                    <span>{strings.tax}</span>
                    <span>{Lib.currency.centsToDollarString(taxCents)}</span>
                </div>

                {staffTipCents ? (
                    <div className={styles.row}>
                        <span>{strings.tip}</span>
                        <span>
                            {Lib.currency.centsToDollarString(staffTipCents)}
                        </span>
                    </div>
                ) : null}

                {charityEnabled ? (
                    <div className={styles.row}>
                        <span>{strings.charity}</span>
                        <span>
                            {Lib.currency.centsToDollarString(
                                appliedCharityAmountCents,
                            )}
                        </span>
                    </div>
                ) : null}

                <div className={styles.row}>
                    <span>{strings.orderTotal}</span>
                    <span>
                        {Lib.currency.centsToDollarString(
                            orderTotalCents + (staffTipCents ?? 0),
                        )}
                    </span>
                </div>

                <div className={styles.spacer} />
                {cashPaidCents !== 0 ? (
                    <div className={styles.row}>
                        <span>{strings.cashPaid}</span>
                        <span className={styles.cashRight}>
                            <span>
                                (
                                {Lib.currency.centsToDollarString(
                                    cashPaidCents,
                                )}
                                )
                            </span>
                            {location.pathname ===
                            routeHelpers.orderComplete() ? null : (
                                <button
                                    className={styles.trashButton}
                                    onClick={() => {
                                        dispatch(
                                            posActions.setPaymentCashPaidCents(
                                                0,
                                            ),
                                        );
                                        dispatch(
                                            posActions.setPaymentChangeOwedCents(
                                                0,
                                            ),
                                        );
                                    }}
                                    disabled={orderSubmitted}>
                                    <img src={trashIcon} alt="" />
                                </button>
                            )}
                        </span>
                    </div>
                ) : null}
                <div className={styles.spacer} />
                {creditCardPaidCents !== 0 ? (
                    <div className={styles.row}>
                        <span>{strings.creditCard}</span>
                        <span className={styles.cashRight}>
                            <span>
                                (
                                {Lib.currency.centsToDollarString(
                                    creditCardPaidCents,
                                )}
                                )
                            </span>
                        </span>
                    </div>
                ) : null}
                {ezCaterApplied ? (
                    <div className={styles.row}>
                        <span>{strings.ezCater}</span>
                        <span className={styles.cashRight}>
                            <span>
                                (
                                {Lib.currency.centsToDollarString(
                                    paidCents - discountAmountCents,
                                )}
                                )
                            </span>
                        </span>
                    </div>
                ) : null}
                {tempDiscountCents ? (
                    <>
                        <div className={styles.spacer} />
                        <div className={styles.discountRow}>
                            <div className={styles.row}>
                                <span>{strings.discountApplied}</span>
                                <span>
                                    {Lib.currency.centsToDollarString(
                                        -tempDiscountCents,
                                    )}
                                </span>
                            </div>
                        </div>
                    </>
                ) : null}
                {discounts.length ? (
                    <>
                        <div className={styles.spacer} />
                        {discounts.map((discount) => {
                            const discountAmount = discount.amountCents
                                ? discount.amountCents
                                : Lib.currency.roundFloat(
                                      (subtotalCents *
                                          (discount.amountPercentage ?? 0)) /
                                          100.0,
                                  );
                            return (
                                <div
                                    key={discount.discountCode}
                                    className={styles.discountRow}>
                                    <div className={styles.row}>
                                        <span>
                                            {discount.name ??
                                                strings.discountApplied}
                                        </span>
                                        <span>
                                            {Lib.currency.centsToDollarString(
                                                -discountAmount,
                                            )}
                                        </span>
                                    </div>
                                    <button
                                        className={styles.removeDiscount}
                                        onClick={() =>
                                            dispatch(
                                                posActions.currentOrderRemoveDiscount(
                                                    discount.discountCode,
                                                ),
                                            )
                                        }
                                        disabled={orderSubmitted}>
                                        {strings.removeDiscount}
                                    </button>
                                </div>
                            );
                        })}
                    </>
                ) : null}
                {giftCardsApplied.length
                    ? giftCardsApplied.map((gc) => (
                          <div key={gc.id} className={styles.gcSection}>
                              <div className={cn(styles.row, styles.gcRow)}>
                                  <span>{strings.giftCard}</span>
                                  <span>
                                      (
                                      {Lib.currency.centsToDollarString(
                                          gc.appliedCents,
                                      )}
                                      )
                                  </span>
                              </div>
                              <div className={cn(styles.gcBalanceRow)}>
                                  <span>{strings.balance}</span>
                                  <span>
                                      {Lib.currency.centsToDollarString(
                                          gc.balanceRemainingCents,
                                      )}
                                  </span>
                              </div>
                              <button
                                  className={styles.removeGiftCardButton}
                                  onClick={() =>
                                      dispatch(
                                          posActions.removePaymentGiftCard(
                                              gc.id,
                                          ),
                                      )
                                  }
                                  disabled={orderSubmitted}>
                                  {strings.removeGiftCard}
                              </button>
                          </div>
                      ))
                    : null}
                {compCardsApplied
                    .filter((cc) => !!cc.appliedCents)
                    .map((cc) => (
                        <div key={cc.id} className={styles.gcSection}>
                            <div className={cn(styles.row, styles.gcRow)}>
                                <span>{strings.compCard}</span>
                                <span>
                                    (
                                    {Lib.currency.centsToDollarString(
                                        cc.appliedCents ?? 0,
                                    )}
                                    )
                                </span>
                            </div>
                            <div className={cn(styles.gcBalanceRow)}>
                                <span>{strings.balance}</span>
                                <span>
                                    {Lib.currency.centsToDollarString(
                                        cc.balance - (cc.appliedCents ?? 0),
                                    )}
                                </span>
                            </div>
                            <button
                                className={styles.removeGiftCardButton}
                                onClick={() =>
                                    dispatch(
                                        posActions.removePaymentCompCard(cc.id),
                                    )
                                }>
                                {strings.removeCompCard}
                            </button>
                        </div>
                    ))}
                {fundraiserCodeApplied ? (
                    <>
                        <div className={styles.spacer} />
                        <div className={styles.fundraiserRow}>
                            <div>{strings.fundraiserApplied}</div>
                            <button
                                className={styles.removeDiscount}
                                onClick={() =>
                                    dispatch(
                                        Actions.currentOrderActions.removeFundraiserCode(),
                                    )
                                }
                                disabled={orderSubmitted}>
                                {strings.removeFundraiser}
                            </button>
                        </div>
                    </>
                ) : null}

                <div className={styles.divider} />

                <div
                    className={cn(
                        balanceDueCents > 0
                            ? styles.balanceDueContainer
                            : styles.row,
                        styles.large,
                    )}>
                    <span>{strings.balanceDue}</span>
                    <span
                        className={
                            balanceDueCents > 0
                                ? styles.balanceDueAmount
                                : undefined
                        }>
                        {Lib.currency.centsToDollarString(
                            tempDiscountCents
                                ? Math.max(
                                      balanceDueCents - tempDiscountCents,
                                      0,
                                  )
                                : balanceDueCents,
                        )}
                    </span>
                </div>
                {/* TODO: replace this once we have updated styles */}
                {getManualProceedButton()}
                {changeOwedCents !== 0 ? (
                    <div className={cn(styles.changeContainer, styles.change)}>
                        <span>{strings.changeOwed}</span>
                        <span className={styles.changeAmount}>
                            {Lib.currency.centsToDollarString(changeOwedCents)}
                        </span>
                    </div>
                ) : null}
            </div>
            {errorMessage ? (
                <ErrorModal
                    errorMessage={errorMessage}
                    onClose={() => setErrorMessage("")}
                    showITInfo
                />
            ) : null}
        </>
    );
}
