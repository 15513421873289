import * as routeHelpers from "../../lib/routeHelpers";
import * as users from "../../lib/api/users";
import cn from "classnames";
import describeNetworkError from "../../lib/describeNetworkError";
import ErrorModal from "../ErrorModal";
import logger from "../../lib/logger";
import OutlineButton from "../OutlineButton";
import React, {useState, useRef, useEffect} from "react";
import styles from "./ManagerPermissionForm.module.css";
import swipeCardIcon from "../../images/swipe-card.svg";
import Spinner from "../Spinner";
import StyledKeyboard from "../StyledKeyboard";
import TextInput from "../TextInput";
import {NavLink} from "react-router-dom";
import {useExternalInput} from "../../lib/api/useExternalInput";

const strings = {
    requiresPermission: "Require’s Manager Permissions",
    instructions1: "A manager must swipe their card in order",
    instructions2: "to access this function.",
    notWorking: "Card Not Working?",
    enterNumber: "Enter Manager ID Manually",
    enterNumberInput: "Manager ID",
    submit: "Submit",
    returnToOrder: "Return To Order",
};

type Props = {
    onSuccess: (data: users.ValidateManagerResponse) => void;
    showReturnPrompt?: boolean;
};

const MANAGER_CARD_LENGTH = 10;

export default function ManagerPermissionForm(props: Props) {
    const [errorMessage, setErrorMessage] = useState("");
    const [employeeId, setEmployeeId] = useState<string | null>(null);
    const {
        externalInput: cardNumber,
        clearExternalInput: clearCardNumber,
        cardReadError,
    } = useExternalInput(employeeId === null);
    const [submittingCardNumber, setSubmittingCardNumber] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);

    useEffect(() => {
        if (inputRef.current && !keyboardCollapsed) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        if (employeeId === "" && inputRef.current) {
            inputRef.current.focus();
            setKeyboardCollapsed(false);
        }
    }, [employeeId]);

    useEffect(() => {
        if (cardNumber.length === MANAGER_CARD_LENGTH) {
            onSubmit();
        }
    }, [cardNumber]);

    function onSubmit() {
        if ((!employeeId && !cardNumber) || submittingCardNumber) {
            return;
        }

        // validate by employee id if card number is not provided
        if (!cardNumber && employeeId) {
            setSubmittingCardNumber(true);
            users
                .validateManagerById(employeeId)
                .then((data) => {
                    setEmployeeId(null);
                    props.onSuccess(data);
                })
                .catch((err) => {
                    logger.warn(err);
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                })
                .finally(() => {
                    setSubmittingCardNumber(false);
                });
        } else {
            setSubmittingCardNumber(true);
            users
                .validateManagerCardNumber(cardNumber)
                .then((data) => {
                    setEmployeeId(null);
                    props.onSuccess(data);
                })
                .catch((err) => {
                    logger.warn(err);
                    const description = describeNetworkError(err);
                    setErrorMessage(description.join("\n"));
                })
                .finally(() => {
                    setSubmittingCardNumber(false);
                    clearCardNumber();
                });
        }
    }

    function onSubmitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        onSubmit();
    }

    function getNonManualContent() {
        return (
            <>
                {!submittingCardNumber ? (
                    <>
                        <OutlineButton
                            className={styles.enterNumberButton}
                            label={strings.enterNumber}
                            onClick={() => {
                                setEmployeeId("");
                            }}
                            type="button"
                        />
                        {errorMessage ? (
                            <div className={styles.pinError}>
                                {errorMessage}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className={styles.cardSwipeLoadingIndicator}>
                        <Spinner />
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <form
                className={styles.permissionContainer}
                onSubmit={onSubmitForm}>
                <img className={styles.icon} src={swipeCardIcon} alt="" />
                <div className={styles.permission}>
                    {strings.requiresPermission}
                </div>
                <div className={styles.instructions}>
                    {strings.instructions1}
                </div>
                <div className={styles.instructions}>
                    {strings.instructions2}
                </div>
                <div className={styles.notWorking}>{strings.notWorking}</div>
                {employeeId === null ? (
                    getNonManualContent()
                ) : (
                    <div className={styles.inputContainer}>
                        <TextInput
                            ref={inputRef}
                            className={styles.pinInput}
                            value={employeeId}
                            onChange={(e) => {
                                setEmployeeId(e.target.value);
                                setErrorMessage("");
                            }}
                            onFocus={() => setKeyboardCollapsed(false)}
                            placeholder={strings.enterNumberInput}
                            required={true}
                            inputMode="none"
                        />
                        {errorMessage ? (
                            <div className={styles.pinError}>
                                {errorMessage}
                            </div>
                        ) : null}
                        <OutlineButton
                            className={styles.submitButton}
                            label={strings.submit}
                            type="submit"
                            disabled={!employeeId}
                            loading={submittingCardNumber}
                        />
                    </div>
                )}
                {props.showReturnPrompt ? (
                    <NavLink
                        className={styles.returnToOrder}
                        to={routeHelpers.menu()}>
                        {strings.returnToOrder}
                    </NavLink>
                ) : null}
            </form>
            <div
                className={cn(
                    styles.keyboardContainer,
                    keyboardCollapsed && styles.keyboardContainerCollapsed,
                )}>
                <StyledKeyboard
                    currentInput={employeeId ?? ""}
                    visible={!keyboardCollapsed}
                    setVisible={(val: boolean) => setKeyboardCollapsed(!val)}
                    onChange={(input) => setEmployeeId(input)}
                    onPressEnter={onSubmit}
                    inputRefs={[inputRef]}
                />
            </div>
            {cardReadError ? (
                <ErrorModal
                    title={cardReadError.title}
                    errorMessage={cardReadError.message}
                    backgroundColor="grey"
                    onClose={() => clearCardNumber()}
                />
            ) : null}
        </>
    );
}
