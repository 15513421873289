import CashPullDrawer from "../CashPullDrawer";
import CashPullModal from "../CashPullModal";
import React, {useState} from "react";
import {CashPullContext} from "../CashPullContext";
import {useContext} from "react";

export function CashPull() {
    const [cashPullDrawerVisible, setCashPullDrawerVisible] = useState(false);
    const {cashPullAlertVisible} = useContext(CashPullContext);

    return (
        <>
            {cashPullAlertVisible ? (
                <CashPullModal
                    onClickProceed={() => setCashPullDrawerVisible(true)}
                />
            ) : null}
            {cashPullDrawerVisible ? (
                <CashPullDrawer
                    onClose={() => setCashPullDrawerVisible(false)}
                />
            ) : null}
        </>
    );
}
