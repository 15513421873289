import * as posModels from "./posModels";
import instance from "./instance";
import {API, Lib} from "habit-core";
import {orders as urls} from "./urls";

function parseOrder(order: posModels.Order): posModels.OrderParsed {
    return {
        ...order,
        orderDate: Lib.dates.safeParseDate(order.orderDate) as Date,
        pickupTime: Lib.dates.safeParseDate(order.pickupTime),
        startTime: Lib.dates.safeParseDate(order.startTime) as Date,
        cancelledTime: Lib.dates.safeParseDate(order.cancelledTime),
    };
}

export function get(
    statuses: posModels.OrderStatus[],
    sources: posModels.OrderSource[],
    training = false,
): Promise<{
    orders: posModels.OrderParsed[];
    total: number;
}> {
    return instance
        .request({
            url: urls.get,
            method: "GET",
            params: {
                status: statuses.join(","),
                source: sources.join(","),
                training,
            },
        })
        .then((response) => ({
            ...response.data,
            orders: response.data.orders.map(parseOrder),
        }));
}

export function place(
    orderId: string,
    referenceNumber: number | null,
    deviceId: string,
    stationMode: posModels.StationMode,
    menuId: string,
    managerId: string | null,
    guestName: string | null,
    pagerNumber: number | null,
    combos: posModels.OrderComboRequest[] | null,
    items: posModels.OrderItemRequest[] | null,
    giftCards: posModels.OrderGiftCardRequest[] | null,
    type: posModels.OrderType,
    charityRoundUpAmountCents: API.models.USDCents,
    staffTipCents: API.models.USDCents | null,
    subtotalCents: API.models.USDCents,
    taxCents: API.models.USDCents,
    totalCents: API.models.USDCents,
    status: posModels.OrderStatus,
    driveThruDetails: posModels.DriveThruDetails | null,
    redemptions: posModels.RedemptionDetail[] | null,
    startTime: Date,
    payments: posModels.PaymentDetail[] | null,
    fundraiserCode: string | null,
    qsrSelections: posModels.QsrSelection[] | null,
): Promise<posModels.OrderParsed> {
    return instance
        .request({
            url: urls.place,
            method: "POST",
            data: {
                orderId,
                referenceNumber,
                deviceId,
                stationMode,
                menuId,
                managerId,
                guestName,
                pagerNumber,
                combos,
                items,
                giftCards:
                    giftCards?.map((gc) => ({
                        amount: Lib.currency.centsToDollarFloat(gc.amountCents),
                        cardNumber: gc.cardNumber,
                    })) ?? null,
                type,
                charityRoundUpAmount: Lib.currency.centsToDollarFloat(
                    charityRoundUpAmountCents,
                ),
                staffTip:
                    staffTipCents !== null
                        ? Lib.currency.centsToDollarFloat(staffTipCents)
                        : null,
                subtotal: Lib.currency.centsToDollarFloat(subtotalCents),
                tax: Lib.currency.centsToDollarFloat(taxCents),
                total: Lib.currency.centsToDollarFloat(totalCents),
                status,
                driveThruDetails,
                startTime: startTime.toISOString(),
                payments: payments ?? [],
                redemptions:
                    redemptions !== null
                        ? redemptions.map((redemption) => ({
                              code: redemption.code,
                              amount: Lib.currency.centsToDollarFloat(
                                  redemption.amountCents,
                              ),
                              type: redemption.type,
                          }))
                        : null,
                fundraiserCode,
                qsrSelections,
            },
        })
        .then((response) => parseOrder(response.data));
}

export function reserveOrderId(
    deviceId: string,
    isTrainingOrder = false,
): Promise<{id: string}> {
    return instance
        .request({
            url: urls.reserveOrderId,
            method: "POST",
            data: {
                deviceId,
                isTrainingOrder,
            },
        })
        .then((response) => response.data);
}

export function reserveOrderReferenceNumber(isTrainingOrder = false): Promise<{
    referenceNumber: number;
}> {
    return instance
        .request({
            url: urls.reserveOrderReferenceNumber,
            method: "POST",
            data: {
                isTrainingOrder,
            },
        })
        .then((response) => response.data);
}

export function paidOut(
    managerId: string,
    amountCents: API.models.USDCents,
    category: posModels.PaidOutReason,
    reason?: string,
): Promise<void> {
    return instance
        .request({
            url: urls.paidOut,
            method: "POST",
            data: {
                managerId,
                amount: Lib.currency.centsToDollarFloat(amountCents),
                category,
                reason,
            },
        })
        .then((response) => response.data);
}

export function cancel(orderId: string): Promise<posModels.OrderParsed> {
    return instance
        .request({
            url: urls.cancel,
            method: "POST",
            data: {
                orderId,
            },
        })
        .then((response) => parseOrder(response.data));
}
