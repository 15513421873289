import {useAppSelector} from "../lib/hooks";
import * as routeHelpers from "../lib/routeHelpers";
import MenuProductLink from "./MenuProductLink";
import React from "react";

type Props = {
    className?: string;
    categoryId: string;
    itemId: string;
    menuId: string;
    numColumns: number;
    displaySize: "small" | "regular";
};

export default function MenuItemLink({
    displaySize = "regular",
    ...props
}: Props) {
    const item = useAppSelector((state) => state.items.byId[props.itemId]);
    const menuItem = useAppSelector(
        (state) => state.menuItems.byMenuId[props.menuId][props.itemId],
    );
    return (
        <MenuProductLink
            className={props.className}
            url={routeHelpers.item(props.categoryId, item.id)}
            name={item.name}
            status={menuItem.status}
            numColumns={props.numColumns}
            statusReason={menuItem.statusReason}
            retinaImages={item.isolatedImages352}
            displaySize={displaySize}
        />
    );
}
