import * as posActions from "../../lib/posActions";
import * as posModels from "../../lib/api/posModels";
import * as routeHelpers from "../../lib/routeHelpers";
import cn from "classnames";
import CurrencyInput from "../CurrencyInput";
import debounce from "lodash/debounce";
import describeNetworkError from "../../lib/describeNetworkError";
import discountErrorIcon from "../../images/discount-error-icon.svg";
import discountInputIcon from "../../images/discount-input-icon.svg";
import discountSuccessIcon from "../../images/discount-success-icon.svg";
import OutlineButton from "../OutlineButton";
import PaymentMethodsHeader from "../PaymentMethodsHeader";
import React, {
    FormEvent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import StyledKeyboard from "../StyledKeyboard";
import styles from "./Discount.module.css";
import TextInputWithResetButton from "../TextInputWithResetButton";
import useOnChangeCashAmount from "../../lib/useOnChangeCashAmount";
import {API, Lib} from "habit-core";
import {CashierModeContext} from "../CashierModeContext";
import {ParallelModeContext} from "../ParallelModeContext";
import {useAppDispatch, useAppSelector} from "../../lib/hooks";
import {useExternalInput} from "../../lib/api/useExternalInput";
import {useLocation, useNavigate} from "react-router-dom";

const strings = {
    instructionLine1: "Please scan the discount barcode or QR code.",
    instructionLine2: "Or you can manually enter the offer number.",
    scanCode: "Scan Code",
    notWorking: "Code Not Working?",
    enterCode: "Enter Code Manually",
    enterDiscountInput: "Enter Discount or Coupon Code Here",
    enterDiscountAmount: "Enter Discount Amount",
    discountAmountPlaceholder: "0.00",
    submit: "Submit",
    valid: "Discount Is Valid!",
    apply: "Apply Discount",
    error: "This Offer Could Not Be Validated",
    ok: "OK",
    discount: "Discount",
    discountDescription: (amountString: string) =>
        `A discount of ${amountString} can be applied to the order`,
};

const TRAINING_DISCOUNT_CODE = "111111";

type NameState = {
    name?: string;
};

export default function Discount() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {isTraining} = useContext(CashierModeContext);
    const {isParallel} = useContext(ParallelModeContext);
    const locationState = location.state as NameState;
    const customerName = locationState?.name ?? null;

    const [discountCode, setDiscountCode] = useState<string | null>(null);
    const [discountType, setDiscountType] =
        useState<posModels.DiscountType | null>(null);
    const {externalInput: externalDiscountCode, clearExternalInput} =
        useExternalInput(discountCode === null, true, false);
    const [validatingDiscountCode, setValidatingDiscountCode] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [showFailureScreen, setShowFailureScreen] = useState(false);

    /* keyboard */
    const [keyboardCollapsed, setKeyboardCollapsed] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const discountAmountRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (
            (inputRef.current || discountAmountRef.current) &&
            !keyboardCollapsed
        ) {
            if (document.activeElement === inputRef.current) {
                inputRef?.current?.scrollIntoView();
            } else if (document.activeElement === discountAmountRef.current) {
                discountAmountRef?.current?.scrollIntoView();
            }
        }
    }, [keyboardCollapsed]);

    useEffect(() => {
        if (discountCode === "" && inputRef.current) {
            inputRef.current.focus();
            setKeyboardCollapsed(false);
        }
    }, [discountCode]);

    const onChangeDiscountCode = (input: string) => {
        setDiscountCode(input);
    };

    const addCode = () => {
        if ((!discountCode && !externalDiscountCode) || discountType === null) {
            return;
        }

        const codeToAdd = discountCode ? discountCode : externalDiscountCode;

        dispatch(
            posActions.currentOrderAddDiscount(
                codeToAdd,
                discountAmountCents,
                discountAmountPercentage,
                discountType,
            ),
        );
        resetState(true);
    };

    const discountsApplied = useAppSelector(
        (state) => state.pos.currentOrder.discounts,
    );

    const isDiscountCodeApplied = (discountCode: string) => {
        const discount = discountsApplied.find(
            (d) => d.discountCode === discountCode,
        );
        return !!discount;
    };

    const onSubmitDiscountCode = (discountCode: string | null) => {
        if (!discountCode || isTraining || isParallel) {
            return;
        }

        if (isDiscountCodeApplied(discountCode)) {
            setDiscountCode(null);
            setShowFailureScreen(true);
            setErrorMessage("This code has already been applied.");
            clearExternalInput();
            return;
        }

        setValidatingDiscountCode(true);
        dispatch(posActions.currentOrderValidateDiscount(discountCode))
            .then((response) => {
                setDiscountAmountCents(response.redemptionAmountCents);
                setDiscountAmountPercentage(
                    response.redemptionAmountPercentage,
                );
                setDiscountType(response.discountType);
                setShowSuccessScreen(true);
            })
            .catch((err) => {
                setDiscountCode(null);
                setShowFailureScreen(true);
                setErrorMessage(describeNetworkError(err).join("\n"));
                clearExternalInput();
            })
            .finally(() => {
                setValidatingDiscountCode(false);
            });
    };

    const debouncedOnSubmitDiscountCode = useCallback(
        debounce(onSubmitDiscountCode, 300),
        [],
    );

    useEffect(() => {
        debouncedOnSubmitDiscountCode(externalDiscountCode);
    }, [externalDiscountCode]);

    const onSubmitDiscountCodeForm = (e: FormEvent) => {
        e.preventDefault();
        debouncedOnSubmitDiscountCode(discountCode);
    };

    const [discountAmountCents, setDiscountAmountCents] =
        useState<API.models.USDCents | null>(null);
    const [discountAmountPercentage, setDiscountAmountPercentage] = useState<
        number | null
    >(null);
    const [displayedDiscountAmount, setDisplayedDiscountAmount] = useState("");
    const onChangeDiscountAmount = useOnChangeCashAmount(
        setDisplayedDiscountAmount,
        setDiscountAmountCents,
    );
    const trainingOnSubmitDiscountAmount = (e: FormEvent) => {
        e.preventDefault();
        dispatch(
            posActions.currentOrderAddDiscount(
                TRAINING_DISCOUNT_CODE,
                discountAmountCents,
                discountAmountPercentage,
                "tracks",
            ),
        );
        resetState(true);
    };

    function renderDiscountInputScreen() {
        const image = (
            <img className={styles.image} src={discountInputIcon} alt="" />
        );
        if (isTraining || isParallel) {
            return (
                <>
                    {image}
                    <div className={styles.mainText}>
                        {strings.enterDiscountAmount}
                    </div>
                    <form
                        className={styles.inputContainer}
                        onSubmit={trainingOnSubmitDiscountAmount}>
                        <CurrencyInput
                            ref={discountAmountRef}
                            value={displayedDiscountAmount}
                            onChange={(num) => setDiscountAmountCents(num)}
                            onFocus={() => setKeyboardCollapsed(false)}
                            placeholder={strings.discountAmountPlaceholder}
                        />
                        <OutlineButton
                            className={styles.submitButton}
                            label={strings.submit}
                            type="submit"
                            disabled={
                                discountAmountCents === null ||
                                discountAmountCents === 0
                            }
                        />
                    </form>
                </>
            );
        }

        return (
            <>
                {image}

                <div className={styles.mainText}>{strings.scanCode}</div>
                <div className={styles.instructionText}>
                    {strings.instructionLine1}
                </div>
                <div className={styles.instructionText}>
                    {strings.instructionLine2}
                </div>
                <div className={styles.notWorking}>{strings.notWorking}</div>
                {discountCode === null ? (
                    <OutlineButton
                        className={styles.enterDiscountCodeButton}
                        label={strings.enterCode}
                        onClick={() => {
                            setDiscountCode("");
                        }}
                        type="button"
                        loading={validatingDiscountCode}
                    />
                ) : (
                    <form
                        className={styles.inputContainer}
                        onSubmit={onSubmitDiscountCodeForm}>
                        <TextInputWithResetButton
                            ref={inputRef}
                            value={discountCode}
                            setValue={(value: string) => {
                                setDiscountCode(value);
                                setErrorMessage("");
                            }}
                            onFocus={() => setKeyboardCollapsed(false)}
                            placeholder={strings.enterDiscountInput}
                            required={true}
                        />
                        <OutlineButton
                            className={styles.submitButton}
                            label={strings.submit}
                            type="submit"
                            disabled={!discountCode}
                            loading={validatingDiscountCode}
                        />
                    </form>
                )}
            </>
        );
    }

    function renderDiscountSuccessScreen() {
        let amountString = Lib.currency.centsToDollarString(
            discountAmountCents ?? 0,
        );
        if (!discountAmountCents) {
            amountString = `${discountAmountPercentage?.toFixed(0)}%`;
        }
        return (
            <>
                <img
                    className={styles.image}
                    src={discountSuccessIcon}
                    alt=""
                />
                <div className={styles.mainText}>{strings.valid}</div>
                <div className={styles.instructionText}>
                    {strings.discountDescription(amountString)}
                </div>
                <OutlineButton
                    className={styles.redeemButton}
                    label={strings.apply}
                    onClick={addCode}
                />
                <div className={styles.errorMessage}>{errorMessage}</div>
            </>
        );
    }

    function resetState(navToCheckout = false) {
        setDiscountCode("");
        clearExternalInput();
        setShowFailureScreen(false);
        setShowSuccessScreen(false);
        setDiscountCode(null);
        setErrorMessage("");
        if (navToCheckout) {
            navigate(routeHelpers.payment(), {
                state: {
                    name: customerName,
                },
            });
        }
    }

    function renderDiscountFailureScreen() {
        return (
            <>
                <img className={styles.image} src={discountErrorIcon} alt="" />
                <div className={styles.mainText}>{strings.error}</div>
                <div className={styles.instructionText}>{errorMessage}</div>
                <OutlineButton
                    className={styles.redeemButton}
                    label={strings.ok}
                    loading={false}
                    onClick={resetState}
                />
            </>
        );
    }

    function renderContent() {
        if (showSuccessScreen) {
            return renderDiscountSuccessScreen();
        } else if (showFailureScreen) {
            return renderDiscountFailureScreen();
        } else {
            return renderDiscountInputScreen();
        }
    }

    return (
        <div className={styles.container}>
            <PaymentMethodsHeader
                backPromptLabel={strings.discount}
                to={routeHelpers.payment()}
            />

            <div className={styles.contentContainer}>{renderContent()}</div>
            <div
                className={cn(
                    styles.keyboardContainer,
                    keyboardCollapsed && styles.keyboardContainerCollapsed,
                )}>
                <StyledKeyboard
                    currentInput={
                        isTraining || isParallel
                            ? displayedDiscountAmount
                            : discountCode ?? ""
                    }
                    visible={!keyboardCollapsed}
                    setVisible={(val: boolean) => setKeyboardCollapsed(!val)}
                    onChange={
                        isTraining || isParallel
                            ? onChangeDiscountAmount
                            : onChangeDiscountCode
                    }
                    onPressEnter={() =>
                        isTraining || isParallel
                            ? () => {
                                  if (!discountAmountCents) {
                                      return;
                                  }
                                  dispatch(
                                      posActions.currentOrderAddDiscount(
                                          TRAINING_DISCOUNT_CODE,
                                          discountAmountCents,
                                          discountAmountPercentage,
                                          "tracks",
                                      ),
                                  );
                                  resetState(true);
                              }
                            : debouncedOnSubmitDiscountCode(discountCode)
                    }
                    inputRefs={[inputRef, discountAmountRef]}
                    layout={isTraining || isParallel ? "numeric" : "default"}
                />
            </div>
        </div>
    );
}
